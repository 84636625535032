import React, { type ReactNode } from 'react'

export type PageBannerProps = {
  readonly backgroundColor?: string
  readonly children: ReactNode
  readonly textColor?: string
}

export function PageBanner({
  backgroundColor = '#f79e00',
  children,
  textColor = '#0F2648'
}: PageBannerProps) {
  return (
    <div
      className="px-4 py-5 text-center"
      data-component="PageBanner"
      style={{
        backgroundColor: backgroundColor,
        color: textColor
      }}
    >
      {children}
    </div>
  )
}
