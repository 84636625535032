import { useLocale } from '@ecomm/data-hooks'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useTrackGuidedSystemBuilderOpenEvent } from '@ecomm/tracking'
import { voidFn } from '@simplisafe/ewok'
import React, { useEffect } from 'react'

import Modal from '../../../lib/Modal'
import { GuidedSystemBuilder } from '../GuidedSystemBuilder'
import { type GuidedSystemBuilderSchema } from '../GuidedSystemBuilder/schema'
import { mapApolloExperiences } from '@ecomm/shared-ninetailed'

interface Props {
  readonly show?: boolean
  readonly onRequestClose?: () => void
  readonly data: GuidedSystemBuilderSchema
}

export function QuoteWizardModal({
  show = false,
  onRequestClose = voidFn,
  data
}: Props) {
  const trackGSBStart = useTrackGuidedSystemBuilderOpenEvent(data.type)
  const locale = useLocale()
  useEffect(() => {
    show && trackGSBStart && trackGSBStart()
  }, [show, trackGSBStart])

  return (
    <Modal
      isOpen={show}
      onRequestClose={onRequestClose}
      style={{ content: { padding: '32px' } }}
    >
      <div className="w-full md:w-[500px]">
        {locale === 'en-US' ? (
          <Experience
            {...data}
            component={GuidedSystemBuilder}
            experiences={mapApolloExperiences(
              data.ntExperiencesCollection?.items || []
            )}
            key={data.id}
          />
        ) : (
          <GuidedSystemBuilder {...data} />
        )}
      </div>
    </Modal>
  )
}
