import {
  applyDiscountCodesRequest,
  useCartAddDiscounts
} from '@ecomm/data-cart'
import { pushToDataLayer } from '@ecomm/shared-window'
import { useTrackMetricEvent } from '@ecomm/tracking'
import { Maybe, localStorage } from '@simplisafe/ewok'
import {
  handleCartResponse,
  setCartLoading
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { selectCartId } from '@simplisafe/ss-ecomm-data/redux/select'

import * as E from 'fp-ts/lib/Either'
import * as RA from 'fp-ts/lib/ReadonlyArray'
import * as O from 'fp-ts/lib/Option'
import { resolve } from 'fluture'
import { identity, pipe } from 'fp-ts/lib/function'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { type Cart } from '@commercetools/platform-sdk'
import { logError } from '@ecomm/error-handling'

const UTM_CODE = 'utm_code'
const { get, remove } = localStorage

type CodesApplied = readonly E.Either<
  { readonly code: string; readonly error: Error },
  Cart
>[]

/**
 * The responsibility of this hook is to iterate through the currentCodes
 * and apply the corresponding ones to the cart.
 *
 * @param currentPromoCodes readonly string[]
 * @param cartLoaded boolean
 * @param cartId string
 */
export function useApplyDiscountCodesToCart(
  currentPromoCodes: readonly string[],
  cartLoaded: boolean,
  useJotaiCart: boolean
) {
  const [areCodesApplied, setAreCodesApplied] = useState(false)
  const trackMetricEvent = useTrackMetricEvent()
  const dispatch = useDispatch()
  const reduxCartId = useSelector(selectCartId).orJust('')
  const jotaiAddDiscounts = useCartAddDiscounts()

  const handleFailure = useCallback(() => {
    setAreCodesApplied(false)
    trackMetricEvent('page-discount-code-error', {
      discountCode: currentPromoCodes.join(',')
    })
  }, [currentPromoCodes.join(',')])

  const handleSuccess = useCallback(() => {
    const utmCode = get(UTM_CODE)
    utmCode && handleUtmApplication(currentPromoCodes)
    setAreCodesApplied(true)
  }, [currentPromoCodes.join(',')])

  useEffect(() => {
    const shouldApplyCodes =
      currentPromoCodes.length > 0 && !areCodesApplied && cartLoaded

    async function reduxApplyDiscounts() {
      dispatch(setCartLoading(true))

      const appliedCodes = await Promise.all(
        pipe(
          currentPromoCodes,
          RA.map(async code =>
            pipe(
              await applyDiscountCodesRequest(reduxCartId, [code])(),
              E.bimap(error => ({ code, error }), identity)
            )
          )
        )
      )

      handleAppliedCodes(
        appliedCodes,
        cart => {
          dispatch(handleCartResponse(resolve(Maybe.of(cart))))
          handleSuccess()
        },
        errorMessage => {
          logError(new Error(errorMessage))
          handleFailure()
        }
      )
    }

    shouldApplyCodes && !useJotaiCart && reduxApplyDiscounts()
    shouldApplyCodes &&
      useJotaiCart &&
      jotaiAddDiscounts(currentPromoCodes, handleFailure, handleSuccess)

    // Using codes.join() in dependency array instead of codes directly.
    // Make sure current codes are applied to the cart if the cart id or promo codes change
  }, [currentPromoCodes.join(','), areCodesApplied, cartLoaded, reduxCartId])
}

function removeUTMCodeOnFailure(code: string) {
  const utmCode = get(UTM_CODE)
  utmCode === code && remove(UTM_CODE)
}

function getErrorMessage(codesApplied: CodesApplied) {
  return pipe(
    codesApplied,
    RA.lefts,
    RA.map(({ code, error }) => {
      removeUTMCodeOnFailure(code)
      return error
    }),
    RA.map(error => error.message),
    RA.reduce('', (current, acc) => `${current} ${acc}`)
  )
}

function handleAppliedCodes(
  codesApplied: CodesApplied,
  onSuccess: (cart: Cart) => void,
  onError: (errorMessage: string) => void
) {
  pipe(
    codesApplied,
    RA.rights,
    RA.head,
    O.fold(
      () => onError(getErrorMessage(codesApplied)),
      cart => onSuccess(cart)
    )
  )
}

function handleUtmApplication(currentPromoCodes: readonly string[]) {
  toast('your discount will be applied in cart', {
    duration: 10000,
    id: 'promoToast',
    position: 'top-right',
    style: {
      margin: 0,
      maxWidth: '380px'
    }
  })
  remove(UTM_CODE)
  pushToDataLayer({
    event: 'toastAlert',
    action: 'AddUTMCodeToCart',
    category: 'UTMAdditionAlert',
    label: currentPromoCodes.toString()
  })
}
