import { type Locale } from '@ecomm/utils'

import type { TrackingData } from '../types/tracking'
import { bmsToTrackingProducts } from '../utils'
import type { PackageProductSchema, ProductSchema } from '../utils/schema'

export const trackBmsView =
  (trackEvent: (data: Partial<TrackingData>) => void) =>
  (
    locale: Locale,
    basePackage: PackageProductSchema,
    productList: readonly ProductSchema[],
    totalPrice: number
  ) => {
    const ecomm = {
      currencyCode: locale === 'en-GB' ? 'GBP' : 'USD',
      detail: {
        products: bmsToTrackingProducts(
          basePackage,
          productList,
          locale,
          totalPrice
        )
      }
    }

    trackEvent({
      ecommerce: ecomm,
      event: 'productDetails'
    })

    trackEvent({
      ecommerce: ecomm,
      event: 'eec.details',
      eventAction: 'detail',
      eventCategory: 'eec',
      eventLabel: 'build my system'
    })
  }
