import { voidFn } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import {
  type ZuoraErrorResponse,
  type ZuoraSuccessResponse
} from '@ecomm/data-zuora'

export function handleHPMSuccess(a: ZuoraSuccessResponse) {
  return pipe(
    O.fromNullable(window.parent?.handleHPMSuccess),
    O.fold(voidFn, fn => fn(a))
  )
}

export function handleHPMFailure(a: ZuoraErrorResponse) {
  return pipe(
    O.fromNullable(window.parent?.handleHPMFailure),
    O.fold(voidFn, fn => fn(a))
  )
}
