import { type LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import { type TrackEvent } from '../analytics'
import { brazeTrackCartEvent } from '../braze'
import { getCommerceDataFromLineItem } from '../getCommerceDataFromLineItems'

const GTM_CLICK_PRODUCT_CART_ACTION = 'system-click'
const GTM_CLICK_PRODUCT_CART_CATEGORY = 'eec'
const GTM_CLICK_PRODUCT_CART_EVENT = 'eec.click'

export const trackSystemClick = (
  lineItem: LineItem,
  trackEvent: TrackEvent,
  lineItems: readonly LineItem[],
  quantity?: number,
  sequence?: number
) => {
  const quantityAdjustedLineItem = {
    ...lineItem,
    quantity: quantity || lineItem.quantity
  }

  const ecomm = getCommerceDataFromLineItem('system-click', false)(lineItems)(
    quantityAdjustedLineItem
  )

  trackEvent({
    ecommerce: ecomm,
    event: 'system-click',
    sequence: sequence
  })

  trackEvent({
    ecommerce: ecomm,
    event: GTM_CLICK_PRODUCT_CART_EVENT,
    eventAction: GTM_CLICK_PRODUCT_CART_ACTION,
    eventCategory: GTM_CLICK_PRODUCT_CART_CATEGORY,
    sequence: sequence
  })

  brazeTrackCartEvent({
    eventType: 'system_click',
    productTrackingData: ecomm
  })
}
