import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { type TrackingData } from '../types/tracking'

const useTrackingProductIncrease = (productName?: string) => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    productName &&
      trackEvent({
        addRemove: 'add',
        event: 'addRemoveEvent',
        sensor: productName
      })
  }, [trackEvent, productName])
}

export default useTrackingProductIncrease
