import { useCartValue } from '@ecomm/data-cart'
import type { CartValue } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { getRudderstack } from '@ecomm/shared-window'
import { voidFn } from '@simplisafe/ewok'
import { getCurrencyFromLocale, getLocale } from '@ecomm/utils'
import type {
  ComponentLineItem,
  LineItem
} from '@simplisafe/eis-commercetools-carts'
import { useCallback } from 'react'
import { match } from 'ts-pattern'
import {
  type EcommEventWithCartId,
  type ProductPayload,
  trackProductAdded,
  trackProductRemoved
} from './ecommEvents'

type QuantityType = 'multiple' | 'single'

export const trackCheckoutSummaryDetails = (label: string) => {
  getRudderstack(r =>
    r.track('checkout_summary_details', {
      label: label
    })
  )
}

export const trackCheckoutPaymentOption = (payment_option: string) => {
  getRudderstack(r =>
    r.track('checkout_payment_option', {
      payment_option
    })
  )
}

export type LoginStatus =
  | 'API_FAILURE'
  | 'INVALID_OR_CLOSED'
  | 'NO_DATA'
  | 'PROCESSING_ERROR'
  | 'SUCCESS'
  | 'TIMEOUT'
export type LoginLocation = 'CHECKOUT'
export type LoggedInProps = {
  readonly status: LoginStatus
  readonly location: LoginLocation
}

export const trackLoggedIn = (props: LoggedInProps) => {
  getRudderstack(r => r.track('logged_in', props))
}

/**
 * Top-level hook to track a LineItem with the Product Added event in Rudderstack
 */
export const useTrackProductAddedToCart = () => {
  const cart = useCartValue()

  return useCallback(
    (lineItem: LineItem) => {
      match(cart)
        .with({ _tag: 'with_items' }, cart => {
          const eventData = lineItemToRudderstackData(lineItem, cart.val)
          trackProductAdded(eventData)
        })
        .otherwise(() => voidFn)
    },
    [cart]
  )
}

/**
 * Top-level hook to track a LineItem with the Product Removed event in Rudderstack
 */
export const useTrackProductRemovedFromCart = () => {
  const cart = useCartValue()

  return useCallback(
    (lineItem: LineItem) => {
      match(cart)
        .with({ _tag: 'with_items' }, cart => {
          const eventData = lineItemToRudderstackData(lineItem, cart.val)
          trackProductRemoved(eventData)
        })
        .otherwise(() => voidFn)
    },
    [cart]
  )
}

/**
 * Top-level hook to track a LineItem was deleted with the Product Removed event in Rudderstack.
 * This differs from Product Removed action because the quantity can be more than one.
 */
export const useTrackProductDeletedFromCart = () => {
  const cart = useCartValue()

  return useCallback(
    (lineItem: LineItem) => {
      match(cart)
        .with({ _tag: 'with_items' }, cart => {
          const eventData = lineItemToRudderstackData(
            lineItem,
            cart.val,
            'multiple'
          )
          trackProductRemoved(eventData)
        })
        .otherwise(() => voidFn)
    },
    [cart]
  )
}

/**
 * @TODO: ECP-12681 - Move these lower-level lineItem helpers into cdp-tracking-utils.
 */
/**
 * Converts an individual LineItem (can be a package) into a data structure that we can send to
 * Rudderstack.
 * The quantityType prop is used to distinguish single increment/decrement from bulk line item
 * deletion. It will allow us to track the proper quantity and price total.
 */
export const lineItemToRudderstackData = (
  lineItem: LineItem,
  cart: CartValue,
  quantityType: QuantityType = 'single'
): EcommEventWithCartId => {
  const locale = getLocale()
  const currency = getCurrencyFromLocale(locale)
  const quantityDivisor = quantityType === 'single' ? lineItem.quantity || 1 : 1

  const total =
    (lineItem.pricesAfterDiscount[locale]?.centAmount || 0) /
    (100 * quantityDivisor)
  const products = lineItemToRudderstackProducts(lineItem, quantityType)
  const eventData = {
    cartId: getCartId() || '',
    coupon: cart.discountCode || '',
    currency,
    products,
    total
  }
  return eventData
}

/**
 * Turns a list of Package LineItem components into a Rudderstack data structure
 * The quantityType prop is used to distinguish single increment/decrement from bulk line item
 * deletion. It will allow us to track the proper quantity and price total.
 */
const packageItemsToRudderstackData = (
  packageItems: readonly ComponentLineItem[],
  packageQuantity: number,
  quantityType: QuantityType = 'single'
): readonly ProductPayload[] => {
  const locale = getLocale()
  const quantityDivisor = quantityType === 'single' ? packageQuantity || 1 : 1

  const items = packageItems.map(packageItem => ({
    category: packageItem.productType,
    item_list_id: '',
    name: packageItem.name[locale],
    price: 0,
    product_id: packageItem.sku,
    quantity: packageItem.quantity / quantityDivisor
  }))
  return items
}

/**
 * Converts an individual LineItem (can be a package) into a data structure to be used in the
 * products array of the Rudderstack data object
 * The quantityType prop is used to distinguish single increment/decrement from bulk line item
 * deletion. It will allow us to track the proper quantity and price total.
 */
const lineItemToRudderstackProducts = (
  lineItem: LineItem,
  quantityType: QuantityType = 'single'
): readonly ProductPayload[] => {
  const locale = getLocale()
  const quantityDivisor = quantityType === 'single' ? lineItem.quantity || 1 : 1
  const quantity = quantityType === 'single' ? 1 : lineItem.quantity

  const price =
    (lineItem.pricesAfterDiscount[locale]?.centAmount || 0) /
    (100 * quantityDivisor)

  if (lineItem.productType === 'package_parent') {
    return [
      {
        category: lineItem.productType,
        item_list_id: lineItem.id,
        name: lineItem.name[locale],
        price,
        product_id: lineItem.sku || '',
        quantity
      },
      ...packageItemsToRudderstackData(
        lineItem.packageItems,
        lineItem.quantity,
        quantityType
      )
    ]
  } else {
    return [
      {
        category: lineItem.productType,
        item_list_id: '',
        name: lineItem.name[locale],
        price,
        product_id: lineItem.sku || '',
        quantity
      }
    ]
  }
}
