import {
  ContentfulImage,
  type ContentfulImageSchema
} from '@ecomm/contentful/components'
import classNames from 'classnames'
import React from 'react'

import { useTrackingPagerClick } from './useTrackingPagerClick'

type ImageGalleryPagerProps = {
  readonly productImages: readonly ContentfulImageSchema[]
  readonly currentImage: number
  readonly onPagerClick: (index: number) => void
  readonly isModal?: boolean
}

export function ImageGalleryPager({
  productImages,
  currentImage,
  onPagerClick: onPagerClickProp,
  isModal = false
}: ImageGalleryPagerProps) {
  const trackPagerClick = useTrackingPagerClick()

  const handlePagerFocus = (index: number) => {
    onPagerClickProp(index)
  }

  const handlePagerClick = (index: number) => {
    onPagerClickProp(index)
    trackPagerClick(index + 1)
  }

  return (
    <div
      className={classNames('order-last col-span-12 mt-4 flex justify-start', {
        'md:order-first md:col-span-2 md:mt-0 md:flex-col md:items-start lg:order-first lg:col-span-2 lg:mt-0 lg:flex-col lg:items-center':
          !isModal
      })}
      data-component="ImageGalleryPager"
    >
      {productImages.map((image, idx) => (
        <div
          className={classNames(
            'mr-2 aspect-square h-14 cursor-pointer overflow-hidden rounded border-2 border-solid transition-all hover:border-[var(--complementary-blue-100)]',
            {
              'border-[var(--neutral-dark-100)]': idx === currentImage,
              'border-white': idx !== currentImage,
              'md:mb-2 md:mr-6 md:h-auto lg:mx-6': !isModal
            }
          )}
          key={idx}
          onClick={() => handlePagerClick(idx)}
          onFocus={() => handlePagerFocus(idx)}
          tabIndex={0}
        >
          <ContentfulImage
            className="h-full w-full object-contain object-center"
            {...image}
          />
        </div>
      ))}
    </div>
  )
}
