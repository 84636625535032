import { currencyCode, getLocale } from '@ecomm/utils'
import { transformObject } from '@simplisafe/ewok'
import { propOr } from '@simplisafe/ewok'
import { path } from '@simplisafe/ewok'
import { overloadMaybe } from '@simplisafe/ewok'
import { type LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { type CurrencyCode } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import {
  type CTLineItemChild,
  type VariantItem,
  getNameFromProductName,
  type productGTM
} from '../analytics'
import { type Ecommerce } from '../types/tracking'

const PACKAGE_PARENT_PRODUCT_TYPE = 'package_parent'
const PACKAGE_PARENT_ID_FIELD_KEY = 'package_parent_id'
const BMS_NAME = 'bms'

/**
 * includeActionInResponse is set true when we don't want the action in the returned object
 * this makes it easier for analytics to parse through data for specific events
 */
export const getCommerceDataFromLineItem =
  (action: string, includeActionInResponse = true) =>
  (lineItems: readonly LineItem[]) =>
  (lineItem: LineItem): Ecommerce => {
    const products = lineItemToEcommProducts(lineItems)(lineItem)

    const ecommProducts = includeActionInResponse
      ? { [action]: { products } }
      : { products }

    return {
      ...ecommProducts,
      currencyCode
    }
  }

export const getCommerceDataFromLineItems =
  (action: string) =>
  (
    lineItems: readonly LineItem[]
  ): Ecommerce & { readonly currencyCode: CurrencyCode } => {
    const products = lineItemsToEcommProducts(lineItems)

    return {
      [action]: { products },
      currencyCode: currencyCode
    }
  }

/* ####### BEGIN LINE ITEM HELPERS ####### */

export const lineItemsToEcommProducts = (lineItems: readonly LineItem[]) =>
  lineItems
    .map(lineItemToEcommProducts(lineItems))
    .reduce((acc, val) => acc.concat(val), [])

/**
 * Take a collection of LineItem representing a cart and a particular LineItem from that collection;
 * generate and return a collection of productGTM, varying behavior for packages, package children,
 * or other.
 *
 * @param lineItems
 * @param lineItem
 */
export const lineItemToEcommProducts =
  (lineItems: readonly LineItem[]) => (lineItem: LineItem) => {
    const packageParentId = O.fromNullable(
      path(['custom', 'fields', PACKAGE_PARENT_ID_FIELD_KEY], lineItem)
    )
    const isPackage =
      O.isSome(packageParentId) &&
      lineItem.productType === PACKAGE_PARENT_PRODUCT_TYPE
    const isPackageChild = !isPackage && O.isSome(packageParentId)

    return isPackage
      ? packageLineItemToEcommProducts(lineItems)(lineItem)
      : isPackageChild
        ? [packageChildLineItemToEcommProduct(lineItems)(lineItem)]
        : [standaloneLineItemToEcommProduct(lineItem)]
  }

/**
 * Take a collection of LineItem representing a cart and a particular LineItem representing a package;
 * generate and return a collection of productGTM consisting of one for this package (with all its
 * children attached as variants) and one for each of those children.
 *
 * Generate and use a "brand" name for these items based on the package to tie them together.
 *
 * Use discount or wholesale price if present, else sum up the value of its components.
 *
 * @param lineItems
 * @param lineItem
 */
export const packageLineItemToEcommProducts =
  (lineItems: readonly LineItem[]) => (lineItem: LineItem) => {
    const brand = getPackageLineItemName(lineItem)

    // generate a top-level return entry for each child
    const childProducts = pipe(
      O.fromNullable(lineItem.child),
      O.map(lineItemChildrenToEcommProducts(brand)),
      O.fold(
        () => [],
        gtmProduct => gtmProduct
      )
    )

    // also convert each child to a variant for this entry
    const childVariants = pipe(
      O.fromNullable(lineItem.child),
      O.map(children => lineItemChildrenToVariants(children)),
      O.fold(
        () => [],
        variants => variants
      )
    )

    // find other line items that are also children of this package
    const childLineItems = pipe(
      getPackageParentIdFromLineItem(lineItem),
      O.map(parentPackageId =>
        findMatchingChildLineItems(parentPackageId)(lineItems)
      ),
      O.fold(
        () => [],
        childLineItem => childLineItem
      )
    )

    // convert those other line items to variants for this entry
    const childLineItemVariants = lineItemsToVariants(childLineItems)

    // all the variants for this entry
    const variants = childVariants.concat(childLineItemVariants)

    childVariants.concat(childLineItemVariants)

    /*
    use either the line item price or, if 0 (eg BMS), sum up its constituent components
    */
    const price = pipe(
      O.fromNullable(getLineItemPriceEach(lineItem)),
      O.filter(price => price > 0),
      O.getOrElse(() => sumLineItemPrices(childLineItems))
    )

    // entry for this particular item, with variants attached
    const lineItemProduct = lineItemToEcommProduct(
      price,
      brand,
      variants
    )(lineItem)

    return [lineItemProduct].concat(childProducts)
  }

/**
 * Take a collection of LineItem representing a cart and a particular LineItem representing a
 * child component of a package; generate and return a productGTM.
 *
 * Find the parent package for this item and get its "brand" name to use in the entry.
 *
 * Use 0 for price so value is not double-counted against the parent package's value.
 *
 * @param lineItems
 * @param lineItem
 */
export const packageChildLineItemToEcommProduct =
  (lineItems: readonly LineItem[]) => (lineItem: LineItem) => {
    // find parent line item, convert to brand name
    const packageBrand = pipe(
      findParentPackageLineItem(lineItems)(lineItem),
      O.map(getPackageLineItemName),
      O.getOrElse(() => '')
    )
    return lineItemToEcommProduct(0, packageBrand)(lineItem)
  }

/**
 * Take a LineItem representing a product that is not a package nor part of a package;
 * generate and return a productGTM.
 *
 * Use discounted or wholesale price as available.
 *
 * @param lineItem
 */
const standaloneLineItemToEcommProduct = (lineItem: LineItem) => {
  const price = getLineItemPriceEach(lineItem)
  return lineItemToEcommProduct(price)(lineItem)
}

/**
 * Get the price per item by dividing total by quantity; round to 2 decimal places
 */
const getLineItemPriceEach = (lineItem: LineItem) => {
  const quantity = propOr(1, 'quantity', lineItem)
  const priceEach = propOr(0, 'totalPrice', lineItem) / quantity
  return parseFloat(priceEach.toFixed(2))
}

/**
 * Take a LineItem and return a Maybe of a non-empty package parent id string
 *
 * @param lineItem
 */
const getPackageParentIdFromLineItem = (lineItem: LineItem) => {
  return pipe(
    O.fromNullable(
      path(['custom', 'fields', PACKAGE_PARENT_ID_FIELD_KEY], lineItem)
    ),
    O.filter(packageParentId => {
      return packageParentId !== ''
    }),
    O.map(String)
  )
}

export const getPackageLineItemName = (lineItem: LineItem) =>
  isLineItemBms(lineItem)
    ? BMS_NAME
    : pipe(
        O.fromNullable(lineItem.name),
        O.chain(lineItemName =>
          pipe(
            overloadMaybe(getNameFromProductName(getLocale())(lineItemName)),
            O.chain(productName => O.fromNullable(productName))
          )
        ),
        O.fold(
          () => '',
          productName => productName
        )
      )

const isLineItemBms = (item: LineItem): boolean =>
  pipe(
    O.fromNullable(path(['custom', 'fields', 'product_is_bms'], item)),
    O.getOrElse(() => false)
  )

/**
 * Take a collection of LineItem and a particular LineItem from that collection;
 * find and return a option of the given LineItem's parent package LineItem if present
 *
 * @param lineItems
 * @param lineItem
 */
export const findParentPackageLineItem =
  (lineItems: readonly LineItem[]) => (lineItem: LineItem) =>
    pipe(
      getPackageParentIdFromLineItem(lineItem),
      O.chain(packageParentId =>
        O.fromNullable(
          lineItems.find(
            otherLineItem =>
              otherLineItem.productType === PACKAGE_PARENT_PRODUCT_TYPE &&
              getPackageParentIdFromLineItem(otherLineItem),
            O.map(lineItemId => lineItemId === packageParentId)
          )
        )
      )
    )

const sumLineItemPrices = (lineItems: readonly LineItem[]) =>
  lineItems.map(propOr(0, 'totalPrice')).reduce((acc, curr) => acc + curr, 0)

export const lineItemChildrenToEcommProducts =
  (brand: string) => (children: readonly CTLineItemChild[]) =>
    children.map(
      transformObject<CTLineItemChild, productGTM>({
        brand: () => brand,
        id: propOr('', 'sku'),
        name: child => {
          return pipe(
            O.fromNullable(child.name),
            O.chain(childName =>
              pipe(
                overloadMaybe(getNameFromProductName(getLocale())(childName)),
                O.chain(productName => O.fromNullable(productName))
              )
            ),
            O.fold(
              () => '',
              childName => childName
            )
          )
        },
        price: () => 0,
        quantity: propOr(0, 'quantity')
      })
    )

export const lineItemChildrenToVariants = (
  children: readonly CTLineItemChild[]
) =>
  children.map(
    transformObject<CTLineItemChild, VariantItem>({
      name: propOr({}, 'name'),
      price: () => 0,
      quantity: propOr(0, 'quantity'),
      sku: propOr('', 'sku')
    })
  )

const lineItemsToVariants = (lineItems: readonly LineItem[]) =>
  lineItems.map(
    transformObject<LineItem, VariantItem>({
      name: propOr({}, 'name'),
      price: () => 0,
      quantity: propOr(0, 'quantity'),
      sku: propOr('', 'sku')
    })
  )

const lineItemToEcommProduct = (
  price: number,
  brand?: string,
  variants?: readonly VariantItem[]
) => {
  return transformObject<LineItem, productGTM>({
    brand: () => brand,
    id: propOr('', 'sku'),
    name: (lineItem: LineItem) => {
      return pipe(
        O.fromNullable(lineItem.name),
        O.chain(lineItemName =>
          pipe(
            overloadMaybe(getNameFromProductName(getLocale())(lineItemName)),
            O.chain(productName => O.fromNullable(productName))
          )
        ),
        O.fold(
          () => '',
          productName => productName
        )
      )
    },
    price: () => price,
    quantity: propOr(0, 'quantity'),
    ...(variants && { variant: () => variants })
  })
}

const findMatchingChildLineItems =
  (parentPackageId: string) => (lineItems: readonly LineItem[]) =>
    lineItems
      .filter(lineItem => lineItem.productType !== PACKAGE_PARENT_PRODUCT_TYPE)
      .filter(
        lineItem =>
          parentPackageId ===
          path(['custom', 'fields', PACKAGE_PARENT_ID_FIELD_KEY], lineItem)
      )
