import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { type TrackingData } from '../types/tracking'

export function useTrackingAppCarousel() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (carouselName: string) => {
      trackEvent({
        event: 'carousel-click-app-component',
        carouselName
      })
    },
    [trackEvent]
  )
}
