import { type PromoImageSchema } from '@ecomm/contentful-schemas'
import { useLocale } from '@ecomm/data-hooks'
import { type Locale } from '@ecomm/utils'
import { overloadMaybe } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import {
  selectCurrentPromoDiscountTextWithOverrides,
  selectCurrentPromoEndTime,
  selectCurrentPromoOverrideDiscountText,
  selectCurrentPromotionMobileBannerImage,
  selectHeroStickerImage
} from '@simplisafe/ss-ecomm-data/redux/select'
import * as O from 'fp-ts/lib/Option'
import { useSelector } from 'react-redux'

import { useMonthsOfFreeServiceText } from '../useMonthsOfFreeServiceText/index'
import { usePromotionBanner } from '../usePromotionBanner'

export type CurrentPromoBannerData = {
  readonly backgroundColor: string
  readonly buttonBackgroundColor: string
  readonly buttonTextColor: string
  readonly disclaimerText: string
  readonly discountText: string
  readonly displayEndTimeCalloutOverride: boolean
  readonly endTime: string
  readonly endTimeCalloutOverride: string
  readonly experimentId?: string
  readonly giftLineItemText: string
  readonly hasCountdown: boolean
  readonly hasEmailInput: boolean
  readonly hasEndDateCallout: boolean
  readonly hasFreeGiftItem: boolean
  readonly hasSaleName: boolean
  readonly heroStickerImage?: PromoImageSchema
  readonly lineItemLabel: string
  readonly lineItemTitle: string
  readonly locale: Locale
  readonly mobileBannerImage?: PromoImageSchema
  readonly monthsOfService?: string
  readonly offerLabel: string
  readonly offerTitle: string
  readonly overrideBannerText?: string
  readonly primaryTextColor: string
  readonly saleName: string
  readonly saleNameLabel?: string
  readonly secondaryTextColor: string
}

export function useCurrentPromoBannerData(): CurrentPromoBannerData {
  const bannerProps = usePromotionBanner()
  const currentPromoMobileBannerImage = overloadMaybe(
    useSelector(selectCurrentPromotionMobileBannerImage)
  )
  const heroStickerImage = overloadMaybe(useSelector(selectHeroStickerImage))
  const monthsOfServiceDisplay = useMonthsOfFreeServiceText(true)
  const locale = useLocale()

  const overrideBannerText = useSelector(selectCurrentPromoOverrideDiscountText)
    .chain(chainProp('promoBannerDiscountText'))
    .orUndefined()

  return {
    backgroundColor: bannerProps
      .chain(chainProp('backgroundColor'))
      .getOrElse(''),
    buttonBackgroundColor: bannerProps
      .chain(chainProp('buttonBackgroundColor'))
      .getOrElse(''),
    buttonTextColor: bannerProps
      .chain(chainProp('buttonTextColor'))
      .getOrElse(''),
    disclaimerText: bannerProps
      .chain(chainProp('disclaimerText'))
      .getOrElse(''),
    discountText: useSelector(
      selectCurrentPromoDiscountTextWithOverrides
    ).getOrElse(''),
    displayEndTimeCalloutOverride: bannerProps
      .chain(safeProp('displayEndTimeCalloutOverride'))
      .getOrElse(false),
    endTime: useSelector(selectCurrentPromoEndTime).getOrElse(''),
    endTimeCalloutOverride: bannerProps
      .chain(chainProp('endTimeCalloutOverride'))
      .getOrElse(''),
    experimentId: bannerProps.chain(chainProp('experimentId')).getOrElse(''),
    giftLineItemText: bannerProps
      .chain(chainProp('giftlineItemText'))
      .getOrElse(''),
    hasCountdown: bannerProps.chain(safeProp('hasCountdown')).getOrElse(false),
    hasEmailInput: bannerProps
      .chain(safeProp('hasEmailInput'))
      .getOrElse(false),
    hasEndDateCallout: bannerProps
      .chain(safeProp('hasEndDateCallout'))
      .getOrElse(false),
    hasFreeGiftItem: bannerProps
      .chain(safeProp('hasFreeGiftItem'))
      .getOrElse(false),
    hasSaleName: bannerProps.chain(safeProp('hasSaleName')).getOrElse(false),
    heroStickerImage: O.toUndefined(heroStickerImage),
    mobileBannerImage: O.toUndefined(currentPromoMobileBannerImage),
    lineItemLabel: bannerProps
      .chain(chainProp('freeGiftItemSecondaryText'))
      .getOrElse(''),
    lineItemTitle: bannerProps
      .chain(chainProp('freeGiftItemPrimaryText'))
      .getOrElse(''),
    locale,
    monthsOfService: monthsOfServiceDisplay,
    offerLabel: bannerProps
      .chain(chainProp('discountSecondaryText'))
      .getOrElse(''),
    offerTitle: useSelector(selectCurrentPromoDiscountTextWithOverrides)
      .map(discountText => `${discountText} off`)
      .getOrElse(''),
    overrideBannerText,
    primaryTextColor: bannerProps
      .chain(chainProp('primaryTextColor'))
      .getOrElse(''),
    saleName: bannerProps.chain(chainProp('saleName')).getOrElse(''),
    saleNameLabel: bannerProps.chain(chainProp('saleNameLabel')).getOrElse(''),
    secondaryTextColor: bannerProps
      .chain(chainProp('secondaryTextColor'))
      .getOrElse('')
  }
}
