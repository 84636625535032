import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { type TrackingData } from '../types/tracking'

export function useTrackingSeeDetailsPlanSelection() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      event: 'see-details-plan-selection'
    })
  }, [trackEvent])
}
