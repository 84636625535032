import { type ErrorResponse } from '@ecomm/data-simplisafe-api'
import { type ShippingMethod } from '@simplisafe/eis-commercetools-shipping-methods'
import { match, P } from 'ts-pattern'

import { type ShippingMethodState } from './shippingMethodStates'

export type Action =
  | readonly ['set_shipping_methods_error', Error | ErrorResponse]
  | readonly ['set_shipping_methods_updating']
  | readonly ['update_shipping_methods', readonly ShippingMethod[]]

export const shippingMethodReducer = (
  state: ShippingMethodState,
  action: Action
): ShippingMethodState => {
  return match(action)
    .with(
      ['set_shipping_methods_error', P.any],
      ([_, payload]): ShippingMethodState => ({
        _tag: 'error',
        val: payload
      })
    )
    .with(
      ['set_shipping_methods_updating'],
      ([_]): ShippingMethodState =>
        match(state)
          .with(
            { _tag: 'error' },
            (): ShippingMethodState => ({
              _tag: 'updating',
              val: []
            })
          )
          .otherwise(
            (s): ShippingMethodState => ({
              _tag: 'updating',
              val: s.val
            })
          )
    )
    .with(
      ['update_shipping_methods', P.any],
      ([_, payload]): ShippingMethodState => ({
        _tag: 'with_items',
        val: payload
      })
    )
    .exhaustive()
}
