import * as Icons from '@ecomm/shared-icons'
import classNames from 'classnames'
import React from 'react'

import { Row } from './Row'
import { type ComparisonTableRowsGroup } from './types'

const { ChevronDown } = Icons

export function RowsGroup({
  isExpanded,
  groupName,
  groupIcon,
  onClick,
  rows
}: ComparisonTableRowsGroup & {
  readonly onClick: () => void
  readonly isExpanded: boolean
}) {
  const Icon = Icons[groupIcon]

  return (
    <>
      <tr
        className={classNames(
          '-mx-[25px] flex items-center border-x-0 border-solid border-[#BAB1AA] bg-[#DDDCDB] px-4 py-3',
          {
            'border-b': isExpanded,
            'cursor-pointer border-b-0 md:last-of-type:rounded-b-2xl':
              !isExpanded
          }
        )}
        onClick={onClick}
      >
        <td className="flex w-full items-center">
          <Icon height={40} width={40} />
          <h3 className="m-0 ml-3 text-sm lg:text-lg">{groupName}</h3>
          <div className="flex grow justify-end">
            <ChevronDown
              className={classNames('transition-transform duration-200', {
                'rotate-180': isExpanded
              })}
              height={24}
              width={24}
            />
          </div>
        </td>
      </tr>
      {isExpanded
        ? rows.map((row, index) => (
            <Row
              hasExpandableRows
              key={`comparison-table-row-${index}`}
              row={row}
            />
          ))
        : null}
    </>
  )
}
