import { useVisitorId } from '@ecomm/data-storage'
import { type OptimizelyEvent } from '@ecomm/optimizely-utils'
import { voidFn } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import * as S from 'fp-ts/lib/string'
import { useCallback, useMemo } from 'react'

import { useReadyClient } from './lib/useReadyClient'
import { useUserAttributes } from './lib/useUserAttributes'

/**
 * Returns a function that can be used to track an optimizely event.
 */
export const useOptimizelyTrack = (data: OptimizelyEvent) => {
  const eventId = useMemo(() => {
    return pipe(
      data.eventKey || '',
      S.replace(/\//g, ''),
      S.replace(/-/g, '_'),
      S.toLowerCase,
      s => (s[s.length - 1] === '_' ? s.substring(0, s.length - 1) : s)
    )
  }, [data.eventKey])

  const client = useReadyClient()

  const visitorId = useVisitorId()

  const userAttributes = useUserAttributes()

  const revenue = useMemo(() => {
    return pipe(
      data.revenue,
      O.fromNullable,
      O.map(n =>
        Number(n * 100)
          .toFixed()
          .toString()
      ),
      O.toNullable
    )
  }, [data.revenue])

  const fn = useCallback(() => {
    client().then(c => {
      pipe(
        O.Do,
        O.bind('_client', () => c),
        O.bind('_userAttributes', () => userAttributes),
        O.bind('_visitorId', () => visitorId),
        O.fold(voidFn, ({ _userAttributes, _visitorId, _client }) => {
          _client.track(eventId, _visitorId, _userAttributes, {
            revenue,
            value: data.value || null
          })
        })
      )
      return O.none
    })
  }, [client, data.value, eventId, revenue, userAttributes, visitorId])

  return fn
}
