import { useIsomorphicLayoutEffect } from '@ecomm/shared-hooks'
import { userAttributes } from '@ecomm/tracking'
import { useOptimizelyParams } from '@ecomm/tracking'
import { type MaybeT, verifyMaybe } from '@simplisafe/ewok'
import {
  type ImmutablePackages,
  type Package,
  initializePackages
} from '@simplisafe/ss-ecomm-data/packages'
import {
  type ImmutableProducts,
  type Product,
  initializeProducts
} from '@simplisafe/ss-ecomm-data/products'
import {
  IOCurrentPromotion,
  IOPromoCode,
  setEvergreenPromotionAction
} from '@simplisafe/ss-ecomm-data/promotions/actions'
import { type EvergreenPromotion } from '@simplisafe/ss-ecomm-data/promotions/lib'
import { type ACTION } from '@simplisafe/ss-ecomm-data/redux/actions'
import { type ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Map, isImmutable } from 'immutable'
import once from 'ramda/src/once'
import React, { type FC, type ReactElement, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { type ThunkDispatch } from 'redux-thunk'

type InjectContextProps = {
  readonly children: ReactElement
  readonly products: ImmutableProducts | { readonly [key: string]: Product }
  readonly packages: ImmutablePackages | { readonly [key: string]: Package }
  readonly evergreenPromotion: MaybeT<EvergreenPromotion>
}

// TODO: this type should be exported out of ss-ecomm-data
type EcommDispatch = ThunkDispatch<ImmutableState, void, ACTION>

/**
 * Data like products and packages need to be added to Redux ASAP (before
 * `useEffect` hooks run) and need to only run once on initial mount.
 * `useLayoutEffect` doesn't play nicely with SSR for things that can affect
 * how a page renders, so this is pulled out into a separate function wrapped
 * in `once` that gets called directly in the component.
 */
const useHydrateReduxOnce = () => {
  const hydrateReduxOnce = useRef(
    once(
      (
        dispatch: EcommDispatch,
        products: InjectContextProps['products'],
        packages: InjectContextProps['packages'],
        evergreenPromotion: InjectContextProps['evergreenPromotion']
      ) => {
        // Products and packages seemingly get turned into JS objects at build time.
        // This turns them back into an Immutable Map before adding them to redux.
        dispatch(
          initializeProducts(isImmutable(products) ? products : Map(products))
        )
        dispatch(
          initializePackages(isImmutable(packages) ? packages : Map(packages))
        )

        // This sets promotion data based on data fetched at build time
        verifyMaybe(evergreenPromotion).forEach(promo =>
          dispatch(setEvergreenPromotionAction(promo))
        )

        verifyMaybe(evergreenPromotion).forEach(promo => {
          verifyMaybe(promo.promoCode).forEach(code =>
            dispatch(IOPromoCode(code))
          )
          verifyMaybe(promo.promoCodeWithMonitoring).forEach(code =>
            dispatch(IOPromoCode(code))
          )
        })
      }
    )
  )

  return hydrateReduxOnce.current
}

const InjectContext: FC<InjectContextProps> = ({
  children,
  products,
  packages,
  evergreenPromotion
}: InjectContextProps) => {
  const dispatch = useDispatch()
  const optimizelyParams = useOptimizelyParams()
  const hydrateReduxOnce = useHydrateReduxOnce()

  hydrateReduxOnce(dispatch, products, packages, evergreenPromotion)

  // Fetch data from the promo service
  useIsomorphicLayoutEffect(() => {
    // pull the current userAttribute data to send to promo service to properly bucket the user
    const attributes = userAttributes()

    dispatch(IOCurrentPromotion(new Date(), attributes, optimizelyParams))
    // Only fetch promo data once on mount
  }, [])

  return <div>{children}</div>
}

export default InjectContext
