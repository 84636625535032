import { type ContentfulRichTextSchema } from '@ecomm/contentful-schemas'
import React from 'react'

import { ContentfulRichText } from '../ContentfulRichText'

type LegalDisclaimerProps = {
  readonly text?: ContentfulRichTextSchema | null
}

export function LegalDisclaimer({ text }: LegalDisclaimerProps) {
  return (
    <div
      className="prose-p:m-0 prose-p:text-xs prose-p:font-light prose-p:leading-relaxed prose-p:text-neutral-dark mt-3 text-center"
      data-component="LegalDisclaimer"
    >
      {text ? <ContentfulRichText raw={text.raw} /> : null}
    </div>
  )
}
