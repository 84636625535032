import { type LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import { type TrackEvent } from '../analytics'
import { getCommerceDataFromLineItems } from '../getCommerceDataFromLineItems'
import { type TrackMetricProps } from '../useTrackMetricEvent'

export const trackShippingPageView = (
  lineItems: readonly LineItem[],
  trackEvent: TrackEvent,
  trackMetricEvent: TrackMetricProps
) => {
  const ecomm = {
    ...getCommerceDataFromLineItems('checkout')(lineItems),
    actionField: { step: 2 }
  }

  trackEvent({
    ecommerce: ecomm,
    event: 'shippingInfo'
  })

  trackEvent({
    ecommerce: ecomm,
    event: 'eec.shipping',
    eventAction: 'checkout',
    eventCategory: 'eec',
    eventLabel: 'shipping information'
  })

  trackMetricEvent('shipping-page-view')
}
