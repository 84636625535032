import { useOptimizelyExcluded } from '@ecomm/data-storage'
import { OptimizelyContext } from '@ecomm/optimizely-utils'
import { type UserAttributes } from '@ecomm/optimizely-utils'
import { pushToDataLayer } from '@ecomm/shared-window'
import { isString } from 'fp-ts/lib/string'
import { useContext, useEffect, useMemo, useState } from 'react'

/**
 * Verifies that the experimentKey is not undefined, null, or an empty string.
 *
 * @deprecated ⚠️⚠️ use `@ecomm/optimizely-hooks` instead ⚠️⚠️
 */
export function experimentKeyIsValid(
  key?: string | null | undefined
): key is string {
  return isString(key) && key !== ''
}

/**
 * An alternative to Optimizely's useActivate hook that can take in a possibly undefined or empty
 * experiment key and will only activate the user if the key is valid and the window exists.
 *
 * @deprecated ⚠️⚠️ use `@ecomm/optimizely-hooks` instead ⚠️⚠️
 */
const useActivate = (
  experimentKey: string | null | undefined,
  overrideUserId?: string | undefined,
  overrideAttributes?: UserAttributes | undefined
): string => {
  const { optimizely } = useContext(OptimizelyContext)
  const [isExcluded] = useOptimizelyExcluded()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  return useMemo(() => {
    const variation =
      ready &&
      experimentKeyIsValid(experimentKey) &&
      !isExcluded &&
      optimizely &&
      optimizely.activate(experimentKey, overrideUserId, overrideAttributes)

    // Push experiment data to GTM and FullStory
    experimentKey &&
      variation &&
      pushToDataLayer({
        event: 'experimentActivation',
        experimentKey,
        variation
      })()

    return variation || ''
  }, [experimentKey, isExcluded, ready])
}

export default useActivate
