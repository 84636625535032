import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { type TrackingData } from '../types/tracking'

const useTrackingPackageDetailTooltip = (tooltip: string) => {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(() => {
    trackEvent({
      action: 'tooltip',
      category: 'package-details',
      event: 'package-detail-tooltip',
      label: tooltip
    })
  }, [trackEvent, tooltip])
}

export default useTrackingPackageDetailTooltip
