import { ZodSchema } from 'zod'

import getexperimentKey from './getExperimentKey'
import getFragmentVariations from './getFragmentVariations'
import getVariationMeta from './getVariationMeta'
import { type VariationFragment } from './types'

function parseSchemaWithVariations<
  Fragment,
  Schema,
  U extends VariationFragment<Fragment> = VariationFragment<Fragment>
>(
  fragment: U | undefined,
  schema: ZodSchema
): Schema & VariationFragment<Fragment> {
  const variations = getFragmentVariations<U, Fragment, Fragment>(
    fragment,
    _variations => schema.parse(_variations)
  )
  const variationMeta = getVariationMeta(fragment)
  const experimentKey = getexperimentKey(fragment)

  return schema.parse({
    ...fragment,
    variations: {
      experimentKey,
      meta: variationMeta,
      variations
    }
  })
}

export default parseSchemaWithVariations
