import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { type ODMONPlacement, type TrackingData } from '../types/tracking'

export function useTrackOdmonTermsServiceClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (placement: ODMONPlacement) => {
      trackEvent({
        placement: placement,
        event: 'terms-of-service-click'
      })
    },
    [trackEvent]
  )
}
