import { type TypeOf, z } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import { ZodType, literal, number } from 'zod'

const productName = z.object({
  'en-US': z.string(),
  'en-UK': z.string()
})

const optionalNumber: ZodType<O.Option<number>> = z
  .object({
    _tag: literal('None')
  })
  .or(
    z.object({
      _tag: literal('Some'),
      value: number()
    })
  )

const variantSchema = z.object({
  isCartable: z.boolean(),
  isIndeterminatePrice: z.boolean(false),
  isOnStock: z.boolean(),
  isSellable: z.boolean(),
  isViewable: z.boolean(),
  hardwareVersion: z.string(),
  price: z.number(),
  quantity: z.number(),
  name: productName,
  sku: z.string()
})

export const commercetoolsSchema = z.object({
  '@@type': z.literal('product'),
  checked: z.boolean(),
  discountedPrice: optionalNumber,
  discountedPriceWithServicePlan: optionalNumber,
  hardwareVersion: z.string(),
  id: z.string(),
  isCartable: z.boolean(true),
  isIndeterminatePrice: z.boolean(false),
  isOnStock: z.boolean(true),
  isSellable: z.boolean(true),
  isViewable: z.boolean(true),
  masterSku: z.string(),
  nodeId: z.number(),
  price: z.number(),
  productType: z.string(),
  productTypeId: z.string(),
  restockDate: z.string(),
  sku: z.string(),
  variants: z.array(variantSchema)
})

export type CommercetoolsProduct = TypeOf<typeof commercetoolsSchema>
