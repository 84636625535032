import { getAtAtToken, getUserId, getVisitorId } from '@ecomm/shared-cookies'
import { isBrowserSupported } from '@simplisafe/ewok'
import { localStorage } from '@simplisafe/ewok'
import { match } from 'ts-pattern'

import { devError } from './devError'
import { getNewRelic } from './getNewRelic'

const { get } = localStorage

export const errorHandler = <E>(__err: E) => {
  const isSupported = isBrowserSupported(navigator.userAgent)

  if (isSupported === true) {
    return true
  } else {
    console.debug('Error is not being reported since browser is unsupported.')
    return false
  }
}

export const configureNewRelic = () => {
  getNewRelic(newrelic => newrelic.setErrorHandler(errorHandler))
}

type ErrorCategory = 'Apollo' | 'Zod' | undefined
type ErrorPriority = 'critical' | 'info' | 'warning'

/**
 * Log an error to the console and newrelic and returns it for further handling (useful for piping)
 * @param error The error to log
 * @param details Additional details to log
 * @param priority Optional field Priority of the error (critical, warning, info)
 * @returns The error that was logged
 */
export const logError = (
  error: Error,
  details: Record<string, string> & {
    readonly errorCategory?: ErrorCategory
  } = {},
  priority?: ErrorPriority
): Error => {
  const vid = getVisitorId()
  const drupalUid = getUserId()
  const atAtToken = getAtAtToken()

  const cartId: string = get('cartId') || ''

  console.log(JSON.stringify(error.message))

  const errorCategory: ErrorCategory =
    details.errorCategory ||
    match(error)
      .when(
        e =>
          e.toString().toLowerCase().includes('zod') ||
          (e.message.includes('expected') && e.message.includes('received')),
        (_): ErrorCategory => 'Zod'
      )
      .when(
        e => {
          const str = e.toString()
          return str.includes('apollo') || str.includes('Apollo')
        },
        (_): ErrorCategory => 'Apollo'
      )
      .otherwise(() => undefined)

  const errorDetails = {
    ...details,
    atAtToken,
    cartId,
    drupalUid,
    environment: process.env['NODE_ENV'],
    vid,
    errorCategory,
    priority: priority ? priority : errorCategory ? 'warning' : 'info',
    // this value will allow us to filter out errors we log on purpose vs unhandled exceptions
    isCaught: 'true'
  }

  devError(error, errorDetails)

  // log the error and details to newrelic after checking if window and newrelic are defined
  getNewRelic(newrelic => newrelic.noticeError(error, errorDetails))

  return error
}
