import { type Locale } from '@ecomm/utils'
import { getCurrencyFromLocale } from '@ecomm/utils'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as RNEA from 'fp-ts/lib/ReadonlyNonEmptyArray'
import * as S from 'fp-ts/lib/string'

export const divideByFractionDigits = (price: number, fractionDigits: number) =>
  price / Math.pow(10, fractionDigits)

export const calculateAndGetRelativeDiscountText = (
  price: number,
  absoluteDiscountWithServicePlan?: number
): O.Option<string> => {
  const hasDiscount = absoluteDiscountWithServicePlan && price

  return hasDiscount
    ? O.some(Math.floor((absoluteDiscountWithServicePlan / price) * 100) + '%')
    : O.none
}
const halfRoundDown = (price: number) =>
  price % 1 === 0.5 ? Math.floor(price) : Math.round(price)

export const halfRoundDownPerLineItem = (quantity: number) => (price: number) =>
  Math.round(halfRoundDown(price * 100) * quantity) / 100

export const applyRelativeDiscount = (discount: number) => (price: number) =>
  price * ((100 - discount) / 100)

const getCentsSymbol = (locale: string) =>
  pipe(
    locale,
    l => S.Eq.equals('en-US', l),
    isUS => (isUS ? '¢' : 'p')
  )

const calculateDailyPriceInCents = (price: number) =>
  pipe(
    price,
    p => (p / 30) * 100,
    p => p.toString(),
    S.split('.'),
    RNEA.head
  )

const isPriceInCents = (price: number) => price / 30 < 1

/**
 * Given a monthly price, return the daily price with the localized cents symbol.
 * This function returns only the integer part of the calculated price. It turns
 * the number into a string and removes the fractional part so that we
 * avoid the rounding up produced by `toFixed()`
 */
export const monthlyToLocalizedDailyPriceInCents = (
  locale: string,
  price: number
): string => `${calculateDailyPriceInCents(price)}${getCentsSymbol(locale)}`

const floorDivide = (divisor: number, dividend: number) =>
  Math.floor((divisor * 100) / dividend) / 100

const ceilDivide = (divisor: number, dividend: number) =>
  Math.ceil((divisor * 100) / dividend) / 100

export const monthlyToLocalizedDailyPriceInDollars = (
  locale: Locale,
  price: number,
  roundingMode: 'ceil' | 'floor' = 'floor'
): string => {
  // eg 1234.567
  // en-US USD => '$1,234.56'
  // en-GB GBP => '£1,234.56'
  const formatter = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: getCurrencyFromLocale(locale),
    currencyDisplay: 'narrowSymbol'
  })

  const rounded =
    roundingMode === 'floor' ? floorDivide(price, 30) : ceilDivide(price, 30)
  return formatter.format(rounded)
}

export const monthlyToLocalizedDailyPrice = (
  locale: Locale,
  price: number
): string =>
  isPriceInCents(price)
    ? monthlyToLocalizedDailyPriceInCents(locale, price)
    : monthlyToLocalizedDailyPriceInDollars(locale, price)

/**
 * SS2 Upgrade flow gets a relative discount applied via customer group, but that info
 * doesn't appear to come back from the pricing service.
 */
export const getSS2UpgradeRelativeDiscount = (): number => {
  const discountOverride = Number(
    process.env['PUBLIC_SS2_UPGRADE_RELATIVE_DISCOUNT']
  )
  const defaultDiscount = 5000
  return discountOverride || defaultDiscount
}

export const getFormattedSS2RelativeDiscount = () => {
  const ss2Discount = divideByFractionDigits(getSS2UpgradeRelativeDiscount(), 2)
  return `${ss2Discount}%`
}
