import React, { useState } from 'react'

import { YoutubeVideo } from '../YoutubeVideo'
import { type YoutubeVideoProps } from '../YoutubeVideo/schema'

import Modal from '../../../lib/Modal'

export default function YoutubeVideoModal({
  text,
  title,
  link
}: YoutubeVideoProps) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-body-size text-neutral-black cursor-pointer border-none bg-transparent p-0 underline hover:no-underline"
        onClick={() => setModalOpen(true)}
      >
        {text}
      </button>
      <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <YoutubeVideo link={link} title={title} />
      </Modal>
    </>
  )
}
