import {
  type PaymentCallbackRequest,
  type PaymentCallbackResponse
} from '@ecomm/data-zuora'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { fetchApi } from './lib/fetchApi'

export function isCallbackResponse(
  res: unknown
): res is PaymentCallbackResponse {
  polyfillHasOwn()
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'success') &&
    Object.values(res).includes(true)
  )
}

export function zuoraPaymentCallback(callBackRequest: PaymentCallbackRequest) {
  return pipe(
    fetchApi({
      endpoint: `/payment-decorator/v1/credit/validate`,
      method: 'POST',
      headers: {},
      body: JSON.stringify(callBackRequest)
    }),
    TE.chain(res =>
      isCallbackResponse(res)
        ? TE.right(res)
        : TE.left(Error(`zuora payment callback request failed ${res}`))
    )
  )
}
