/**
 * This is a collection of functions that handle error responses from the carts API.
 */
import { getCartId, removeCartId } from '@ecomm/data-storage'
import * as TE from 'fp-ts/lib/TaskEither'

import { cartFetch } from './cartFetch'
import { constructCartEndpoint } from './constructCartEndpoint'
import { type ErrorResponse } from './types'

/**
 * Handle unauthorized cart responses.
 *
 * If the error is a 403 and we have a cart ID, replicate the cart
 */
export const handleUnauthorizedCart = (
  error: Error | ErrorResponse
): TE.TaskEither<Error | ErrorResponse, unknown> => {
  const isError = error instanceof Error
  const cartId = getCartId()
  const isUnauthorized =
    !isError && !error.isPerimeterXError && error.status === 403

  return isUnauthorized && cartId
    ? cartFetch('POST', constructCartEndpoint(1, `replicate/${cartId}`))
    : TE.left(error)
}

/**
 * Handle not found cart responses.
 */
export const handleNotFoundCart = (
  error: Error | ErrorResponse
): TE.TaskEither<Error | ErrorResponse, unknown> => {
  removeCartId()
  return TE.left(error)
}
