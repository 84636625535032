import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import {
  type ExperienceConfiguration,
  useExperience as _useExperience
} from '@ninetailed/experience.js-gatsby'
import { type Reference } from '@ninetailed/experience.js-shared'

type Props<T extends Reference, U extends Reference> = {
  baseline: T
  experiences: ExperienceConfiguration<U>[]
}

export function useExperience<
  Baseline extends Reference,
  Variant extends Reference
>({
  baseline,
  experiences
}: Props<Baseline, Variant>): ReturnType<typeof _useExperience> {
  const locale = useLocale()

  try {
    return _useExperience({ baseline, experiences })
  } catch (_err) {
    const err = _err instanceof Error ? _err : Error(`${JSON.stringify(_err)}`)

    // we want to log an error if the locale is en-US since we expect to have a ninetailed provider
    locale === 'en-US' && logError(err)

    return {
      status: 'error',
      loading: false,
      hasVariants: false,
      baseline,
      experience: null,
      variant: baseline,
      variantIndex: 0,
      audience: null,
      isPersonalized: false,
      profile: null,
      error: err
    }
  }
}
