import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { type TrackingData } from '../types/tracking'

export function useTrackingRemoveMonitoring() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      event: 'remove-monitoring'
    })
  }, [trackEvent])
}
