import { addAtomDebugLabel } from '@ecomm/utils'
import { localStorage } from '@simplisafe/ewok'
import { atom } from 'jotai'

import { type Action, cartReducer } from './cartReducer'
import { type Cart } from './cartStates'

// During a static build we want to return not_initialized so that
// the initial HTML on initial load doesn't render an empty cart UI.
export const baseCartAtom = atom<Cart>({
  _tag:
    typeof window === 'object' && !localStorage.get('cartId')
      ? 'no_cart_id'
      : 'not_initialized'
})

export const cartAtom = atom(
  get => get(baseCartAtom),
  (get, set, update: Action) => {
    const prev = get(baseCartAtom)
    const next = cartReducer(prev, update)
    set(baseCartAtom, next)
  }
)

addAtomDebugLabel(cartAtom, 'Cart')
