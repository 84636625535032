export const EXPERIENCE_CTFL_ID = '6ahf8pkazximVAkWrWZ5xg'

export const BASELINE_CTFL_ID = '2uosOt6dmR3EsTLOD8se75'
export const VARIANT1_CTFL_ID = 'nsEBAY4OmkDo7iJmaYbps'
export const VARIANT2_CTFL_ID = '3tKa8qslwfNkGIdNeU99HC'
export const VARIANT3_CTFL_ID = BASELINE_CTFL_ID

export const getVariantIndexes = () => [1, 2]

export const CHOOSE_MONITORING_URL = '/choose-monitoring'
export const CHOOSE_MONITORING_URL_REPLACEMENT = '/choose-monitoring-3'

export const CHANGE_MONITORING_URL = '/change-monitoring'
export const CHANGE_MONITORING_URL_REPLACEMENT = '/change-monitoring-3'
export const OUTDOOR_CAMERA_CHANGE_MONITORING_URL_REPLACEMENT =
  '/change-monitoring-4'
