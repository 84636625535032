import React from 'react'

import { GatsbyImage } from '../GatsbyImage'
import { type VerifyPassFeatureData } from './schema'

interface Props {
  readonly data: VerifyPassFeatureData
}

function VerifyPassFeature({ data }: Props) {
  const { featureText, icon } = data

  return (
    <div className="flex flex-row justify-center gap-2 bg-[#f1f1f2] p-4 align-middle md:gap-10 md:px-8">
      <div className="min-h-20 min-w-20 md:min-h-28 md:min-w-28">
        <GatsbyImage className="h-20 w-20 md:h-28 md:w-28" image={icon} />
      </div>
      <div className="flex grow items-center">
        <p className="m-0 block w-full text-center align-middle md:text-xl">
          {featureText}
        </p>
      </div>
    </div>
  )
}

export default VerifyPassFeature
