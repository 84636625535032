import { monthlyToLocalizedDailyPriceInCents } from '@ecomm/data-price'
import { type Locale } from '@simplisafe/ewok'
import { localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
import { addDays, format } from 'date-fns'

const regexPricePerDay = /(?:{{price_per_day}})/g
const regexPricePerMonth = /(?:{{price_per_month}})/g

export const replaceTokensWithPrice = (
  locale: Locale,
  text: string,
  price: number
): string =>
  text
    .replace(
      regexPricePerDay,
      `${monthlyToLocalizedDailyPriceInCents(locale, price)}/day`
    )
    .replace(
      regexPricePerMonth,
      `${localizedDisplayPrice(locale)(price).orUndefined()} per month`
    )

const regexMonthsFreeService = /(?:{{months_free_service}})/g
const regexMonthsFreeServiceCount = /(?:{{months_free_service_count}})/g

export const replaceTokensWithMonths = (
  text: string,
  monthsOfService: string
): string => {
  const monthsOfServiceDisplay =
    monthsOfService.match(/\d/) !== null
      ? monthsOfService
      : `a ${monthsOfService}`

  const monthsOfServiceCount =
    monthsOfService.match(/\d/) !== null
      ? `${monthsOfService.match(/\d/)} month`
      : `one-month`

  return text
    .replace(regexMonthsFreeService, monthsOfServiceDisplay)
    .replace(regexMonthsFreeServiceCount, monthsOfServiceCount)
}

export const regexEligibilityDate =
  /(?:{{us_visa_gift_card_eligibility_date}})/g

export const replaceTokensWithVisaGiftCardEligibilityDate = (
  text: string,
  endDate: string
): string => {
  return text.replace(regexEligibilityDate, add35DaysToEndDate(endDate))
}

export const add35DaysToEndDate = (endDate: string) =>
  format(addDays(new Date(endDate), 35), 'MMM dd, yyyy')
