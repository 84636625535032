import { type FormikHelpers } from 'formik'
import { type TypeOf, z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const idSchema = z.object({
  id: z.string()
})

const EMAIL_ERROR = 'Please enter a valid email address.'
const formFieldsSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  companyName: z.string(),
  email: z.string({ required_error: EMAIL_ERROR }).email(EMAIL_ERROR)
})
export const formQuerySchema = z.object({
  __typename: z.literal('Form'),
  contentful_id: idSchema.transform(sysObj => sysObj.id),
  sys: idSchema,
  formCollection: z.object({
    items: z
      .array(
        z.object({
          label: z.string(),
          placeholder: z.string(),
          type: z.enum(['Text', 'File', 'Text Area', 'Email']),
          name: z.string()
        })
      )
      .default([])
  }),
  button: z.object({
    text: z.string()
  })
})
export const validationSchema = toFormikValidationSchema(formFieldsSchema)

export const partnerWithUsPayloadSchema = formFieldsSchema
  .extend({
    businessType: z.string(),
    numLocations: z.string(),
    phone: z.string(),
    source: z.string()
  })
  .strict()

export type FormFieldsSchema = TypeOf<typeof formFieldsSchema>
export type OnSubmit = (
  values: FormFieldsSchema,
  formikHelpers: FormikHelpers<FormFieldsSchema>
) => void
export type SubmissionResultProps = {
  readonly result: 'error' | 'pending' | 'success'
}
export type FormQuerySchema = TypeOf<typeof formQuerySchema>
export type PartnerWithUsPayloadSchema = Required<FormFieldsSchema> &
  TypeOf<typeof partnerWithUsPayloadSchema>
