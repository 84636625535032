import { type ContentfulImageSchema } from '@ecomm/contentful/components'
import { getContentfulImageFromGatsbyData } from '@ecomm/contentful-image-utils'
import type { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import classNames from 'classnames'
import { identity } from 'fp-ts/lib/function'
import { not } from 'fp-ts/lib/Predicate'
import { map } from 'fp-ts/lib/ReadonlyArray'
import React, { useMemo, useState } from 'react'
import { match, P } from 'ts-pattern'

import { ImageGalleryCurrent } from './ImageGalleryCurrent'
import { ImageGalleryPager } from './ImageGalleryPager'

type ProductImages = ReadonlyArray<ContentfulImageSchema | GatsbyImageSchema>
type ImageGalleryProps = {
  readonly productImages: ProductImages
  readonly isModal?: boolean
}

const containsGatsbyImages = (
  images: ProductImages
): images is ReadonlyArray<GatsbyImageSchema> =>
  images.some(image => 'gatsbyImageData' in image)

const containsContentfuImages = (
  images: ProductImages
): images is ReadonlyArray<ContentfulImageSchema> =>
  not(containsGatsbyImages)(images)

const getContentfulImages = (images: ProductImages) =>
  match(images)
    .with(P.when(containsGatsbyImages), map(getContentfulImageFromGatsbyData))
    .with(P.when(containsContentfuImages), identity)
    .run()

export function ImageGallery({
  productImages,
  isModal = false
}: ImageGalleryProps) {
  const [currentImage, setCurrentImage] = useState(0)
  const contentfulImages = useMemo(
    () => getContentfulImages(productImages),
    [productImages]
  )
  const current = productImages && contentfulImages[currentImage]

  return (
    <div
      className={classNames(
        'col-span-12 mb-6 inline-grid max-w-none grid-cols-12 items-stretch overflow-hidden',
        {
          'md:col-span-12 lg:col-span-6 lg:row-span-2': !isModal
        }
      )}
      data-component="ImageGallery"
    >
      <ImageGalleryPager
        currentImage={currentImage}
        isModal={isModal}
        onPagerClick={setCurrentImage}
        productImages={contentfulImages}
      />
      <ImageGalleryCurrent current={current} isModal={isModal} />
    </div>
  )
}
