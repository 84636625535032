import {
  type ContentfulRichTextSchema,
  type GatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { ContentfulRichText } from '@ecomm/shared-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { type Output as ComponentProps } from '../../hooks/useLocalizedData'
import { ImageColumn } from './ImageColumn'
import { schema } from './schema'

const getGatsbyImage = (imgData: GatsbyImageSchema) => (
  <GatsbyImage
    alt={imgData.description || ''}
    className="h-full w-full"
    image={imgData.gatsbyImageData}
    objectFit="cover"
  />
)

const getContent = (richTextProps: ContentfulRichTextSchema) => (
  <div
    className="prose md:prose-md lg:prose-lg whitespace-pre-line"
    data-component="TwoColumnImageBlurbContent"
  >
    <ContentfulRichText raw={richTextProps.raw} />
  </div>
)

type TwoColumnImageBlurbsProps = ComponentProps<typeof schema>

export function TwoColumnImageBlurbs({
  data
}: {
  readonly data: TwoColumnImageBlurbsProps
}) {
  return (
    <div className="md:flex md:justify-between md:gap-x-9">
      <div className="mb-10 flex-1 md:mb-0">
        {data?.image1?.gatsbyImageData ? (
          <ImageColumn>{getGatsbyImage(data.image1)}</ImageColumn>
        ) : null}
        {getContent(data.description1)}
      </div>
      <div className="mb-10 flex-1 md:mb-0">
        {data?.image2?.gatsbyImageData ? (
          <ImageColumn>{getGatsbyImage(data.image2)}</ImageColumn>
        ) : null}
        {getContent(data.description2)}
      </div>
    </div>
  )
}
