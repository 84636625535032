import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { brazeSetCustomUserAttributes } from '../braze'
import { type TrackingData } from '../types/tracking'

export const useTrackingEpsilonAbacusOptIn = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (epsilonAbacusOptIn: boolean) => {
      trackEvent({
        label: epsilonAbacusOptIn.toString(),
        event: 'epsilonAbacusToggle'
      })
      brazeSetCustomUserAttributes()
    },
    [trackEvent]
  )
}
