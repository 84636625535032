import { document } from 'browser-monads-ts'
import { constUndefined, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { type MutableRefObject, useEffect } from 'react'

export function useOnClickOutside(
  callback: () => void,
  ref: MutableRefObject<HTMLElement | null>,
  documentWrapperId = ''
) {
  useEffect(() => {
    function handler(event: Event) {
      ref.current &&
        event.target instanceof Node &&
        !ref.current.contains(event.target) &&
        callback()
    }

    return pipe(
      document,
      O.fromNullable,
      O.chain(doc =>
        O.fromNullable(
          documentWrapperId ? doc.getElementById(documentWrapperId) : doc
        )
      ),
      O.match(constUndefined, appWrapper => {
        appWrapper.addEventListener('mousedown', handler)
        appWrapper.addEventListener('touchstart', handler)
        return () => {
          appWrapper.removeEventListener('mousedown', handler)
          appWrapper.removeEventListener('touchstart', handler)
        }
      })
    )
  }, [callback, documentWrapperId, ref])
}
