import { type IconTypes } from '@ecomm/shared-icons'

import { Locale } from '../../hooks/useLocalizedData'
import { createMockRichText } from '../../utils'

export const data = {
  [Locale.EnGB]: {
    header: {
      title: '24/7 Whole home monitoring',
      description:
        "With the Pro Premium monitoring plan, our agents act quickly in an emergency. Here's how."
    },
    cta: {
      text: 'Learn about Pro Premium Monitoring',
      link: '/professional-alarm-monitoring'
    },
    alarmCategories: [
      {
        id: 0,
        name: 'Live Guard Protection',
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=483&h=264&q=50&fm=webp 483w,\nhttps://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=966&h=528&q=50&fm=webp 966w,\nhttps://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=1932&h=1056&q=50&fm=webp 1932w',
                  sizes: '(min-width: 1932px) 1932px, 100vw',
                  type: 'image/webp'
                }
              ],
              fallback: {
                src: 'https://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=483&h=264&fl=progressive&q=50&fm=jpg 483w,\nhttps://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=966&h=528&fl=progressive&q=50&fm=jpg 966w,\nhttps://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg 1932w',
                sizes: '(min-width: 1932px) 1932px, 100vw'
              }
            },
            layout: 'constrained',
            width: 1932,
            height: 1056,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAYEBf/EACMQAAIABgEEAwAAAAAAAAAAAAECAAMEERIxBRUhInFSYZH/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/xAAZEQACAwEAAAAAAAAAAAAAAAABAgADESH/2gAMAwEAAhEDEQA/AOUhVnAORt8dmNqTZWEgMgIyYNddX1Fx0TjQ+YpEBtbsTa3q8H4igxsKZR6JEM7q4wwVRlOyA5CQaSciAnyQNu+yfoQi6lcTQ1CZTpGbDxBLNr9hGhcM7IajvJ//2Q=='
            }
          },
          title: 'Illustration Live Guard Protection'
        },
        bubbles: [
          {
            icon: <IconTypes>'PersonRunning',
            text: 'Motion is detected. The Smart Alarm Wireless Indoor Camera springs into action.'
          },
          {
            icon: <IconTypes>'Video',
            text: 'Onboard AI determines the movement is human, which triggers the alarm and begins recording.'
          },
          {
            icon: <IconTypes>'PoliceCar',
            text: 'Police response is requested upon a visually verified alarm.'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Agents call you or your listed contacts directly to inform you of what has happened with your alarm.'
          }
        ],
        disclaimerText: createMockRichText([
          '**Vs. non-verified SimpliSafe® alarms. With video verification, our agents capture evidence so that the response is precise. Pro Premium monitoring plan required.'
        ])
      }
    ]
  },
  [Locale.EnUS]: {
    header: {
      title: '24/7 live guard protection in action',
      description:
        'With a Fast Protect™ Monitoring plan, our highly trained agents act quickly in an emergency. Here’s how.'
    },
    cta: {
      text: 'Learn about Fast Protect™ Monitoring',
      link: '/features-alarm-monitoring'
    },
    alarmCategories: [
      {
        id: 0,
        name: 'Live Guard Protection',
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=483&h=264&q=50&fm=webp 483w,\nhttps://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=966&h=528&q=50&fm=webp 966w,\nhttps://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=1932&h=1056&q=50&fm=webp 1932w',
                  sizes: '(min-width: 1932px) 1932px, 100vw',
                  type: 'image/webp'
                }
              ],
              fallback: {
                src: 'https://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=483&h=264&fl=progressive&q=50&fm=jpg 483w,\nhttps://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=966&h=528&fl=progressive&q=50&fm=jpg 966w,\nhttps://images.ctfassets.net/br4ichkdqihc/5YmMhUvL3ceRQS4SuA9Eyq/c94d3a8e1f76a129e7c420f6cc860855/Scout_q.jpg?w=1932&h=1056&fl=progressive&q=50&fm=jpg 1932w',
                sizes: '(min-width: 1932px) 1932px, 100vw'
              }
            },
            layout: 'constrained',
            width: 1932,
            height: 1056,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAYEBf/EACMQAAIABgEEAwAAAAAAAAAAAAECAAMEERIxBRUhInFSYZH/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/xAAZEQACAwEAAAAAAAAAAAAAAAABAgADESH/2gAMAwEAAhEDEQA/AOUhVnAORt8dmNqTZWEgMgIyYNddX1Fx0TjQ+YpEBtbsTa3q8H4igxsKZR6JEM7q4wwVRlOyA5CQaSciAnyQNu+yfoQi6lcTQ1CZTpGbDxBLNr9hGhcM7IajvJ//2Q=='
            }
          },
          title: 'Illustration Live Guard Protection'
        },
        bubbles: [
          {
            icon: <IconTypes>'PersonRunning',
            text: 'Motion is detected. The Smart Alarm Wireless Indoor Camera springs into action.'
          },
          {
            icon: <IconTypes>'Video',
            text: 'Onboard AI determines the movement is human, which triggers the alarm and begins recording.'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Agents check camera recordings and live video feeds for intruders, telling them police are on their way.'
          },
          {
            icon: <IconTypes>'PoliceCar',
            text: 'Police are dispatched through 911 services, and verified threats are given priority.*'
          },
          {
            icon: <IconTypes>'HeadsetPerson',
            text: 'Agents call you directly to inform you of what has happened with your alarm and that police have been dispatched.'
          }
        ],
        disclaimerText: createMockRichText([
          '*Vs. non-verified SimpliSafe® alarms. With video verification, our agents capture evidence so that you can get priority response. Fast Protect™ Monitoring plan is required.'
        ])
      }
    ]
  }
}
