import { type PartnerPromoBannerData } from '.'

export const partnerBannerDataMock: PartnerPromoBannerData = {
  backgroundColor: '#C84A3B',
  discountText: '25% Off',
  discountSecondaryText: 'any new system',
  displayEmailInputField: true,
  hasPartnerBanner: true,
  lineItemText: '+ free SimpliCam',
  linkPath: '/xyz-partner',
  logoDescription: 'image of xyz partner logo',
  logoUrl: '/path-to-xyz-partner-logo.jpg',
  monthsOfService: '2 free months of monitoring',
  partnerName: 'xyz-partner',
  primaryOfferText: '25% Off',
  primaryTextColor: '#000000',
  secondaryOfferText: '+ Free Camera',
  secondaryOfferTextMobile: '+ Free Camera Mobile',
  secondaryTextColor: '#EAEAEA'
}
