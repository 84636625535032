import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { type Output as ComponentProps } from '../../hooks/useLocalizedData'
import { schema } from './schema'

type TwoColumnImagesProps = ComponentProps<typeof schema>

export function TwoColumnImages({
  data
}: {
  readonly data: TwoColumnImagesProps
}) {
  const { image1, image2 } = data

  const leftImage = image1 && (
    <GatsbyImage
      alt={image1.description || ''}
      image={image1.gatsbyImageData}
      objectFit="contain"
    />
  )

  const rightImage = image2 && (
    <GatsbyImage
      alt={image2.description || ''}
      image={image2.gatsbyImageData}
      objectFit="contain"
    />
  )

  return (
    <div className="rounded-base bg-neutral-black w-full justify-between p-2.5 md:flex md:gap-x-9 md:p-8">
      <div className="mx-auto max-w-md md:mx-0 md:max-w-none">{leftImage}</div>
      <div className="mx-auto max-w-md md:mx-0 md:max-w-none">{rightImage}</div>
    </div>
  )
}
