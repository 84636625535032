import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { type TrackingData } from '../types/tracking'

export function useTrackingAlarmMoments() {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(
    (carouselTab: number) => {
      trackEvent({
        event: 'click-monitoring-alarm-carousel',
        carouselTab
      })
    },
    [trackEvent]
  )
}
