import { type ScoutVideoType } from '.'

// TODO: We will revisit the styling aspects of these props when we have the correct
// video assets (based on aspect ratio and resolution) to ensure they can work
// accross our different viewports.
export type ScoutVideoMetadata = {
  readonly videoSrcUrlWebm: string
  readonly videoSrcUrlMp4: string
  readonly captionsSrcUrl?: string
  readonly poster?: string
  readonly preload?: string
}

const AlarmMonitoringScoutVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/monitoring_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/monitoring_video.mp4',
  captionsSrcUrl:
    'https://video.simplisafe.com/marketing/scout/monitoring_caption.vtt',
  poster:
    'https://video.simplisafe.com/marketing/scout/monitoring_hp_poster.jpg',
  preload: 'none'
}

const ScoutProductLiveGuardVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://videos.ctfassets.net/br4ichkdqihc/1Mgb9RUHO4DrTnuJeSwRw9/eb7e67464a2100f9eac312adddc047ce/scout_uk_pdp.webm',
  videoSrcUrlMp4:
    'https://videos.ctfassets.net/br4ichkdqihc/48P2urOafm4w36OdCeN6qh/90bd9fabe2d86b5aaf81de95f3d9cbf8/scout_uk_pdp.mp4',
  poster:
    'https://images.ctfassets.net/br4ichkdqihc/4Q7yBOLuPemrW3tge4iT3E/42ee5acfcde803f40b9e0f1301c0be8c/scout_video_poster_1.jpg',
  preload: 'none'
}

const ScoutProductInstallationVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/scout_installation_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/scout_installation_video.mp4',
  poster:
    'https://video.simplisafe.com/marketing/scout/scout_installation_poster.jpg',
  preload: 'none'
}

const ScoutHomePageVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/scout_hp_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/scout_hp_video.mp4',
  captionsSrcUrl:
    'https://video.simplisafe.com/marketing/scout/scout_hp_captions.vtt',
  poster:
    'https://video.simplisafe.com/marketing/scout/monitoring_hp_poster.jpg',
  preload: 'none'
}

export const data: Record<ScoutVideoType, ScoutVideoMetadata> = {
  AlarmMonitoringScoutVideo,
  ScoutProductLiveGuardVideo,
  ScoutProductInstallationVideo,
  ScoutHomePageVideo
}
