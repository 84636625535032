import React from 'react'
import { type TypeOf } from 'zod'

import { headerSchema } from './schema'

type HeaderProps = TypeOf<typeof headerSchema>

export function AlarmMomentsHeader({ title, description }: HeaderProps) {
  return (
    <div className="mb-2 px-3 md:px-0" data-component="AlarmMomentsHeader">
      <h2 className="text-heading-3-size leading-h3-height my-2.5 text-center md:my-4">
        {title}
      </h2>
      <p className="m-0 text-center">{description}</p>
    </div>
  )
}
