import {
  ContentfulImage,
  type ContentfulImageSchema
} from '@ecomm/contentful/components'
import classNames from 'classnames'
import React from 'react'

type ImageGalleryCurrentProps = {
  readonly current: ContentfulImageSchema
  readonly isModal?: boolean
}

export function ImageGalleryCurrent({
  current,
  isModal = false
}: ImageGalleryCurrentProps) {
  return (
    <div
      className={classNames('col-span-12 flex', {
        'md:col-span-10 lg:col-span-10': !isModal
      })}
      data-component="ImageGalleryCurrent"
    >
      <ContentfulImage
        className="w-full rounded-lg object-contain object-center lg:max-h-[450px]"
        {...current}
      />
    </div>
  )
}
