import { ChevronDown } from '@ecomm/shared-icons'
import React, { type ReactNode } from 'react'

export type AccordionProps = {
  readonly id: string
  readonly title: string
  readonly children: ReactNode
  readonly isExpanded: boolean
  readonly dataComponent?: string
}

/**
 * @deprecated Only used in legacy; use `import { Accordion } from '@ecomm/shared-components` instead
 */
export function Accordion({
  title,
  children,
  isExpanded,
  dataComponent = 'Accordion'
}: AccordionProps) {
  const headerClass = `m-0 px-4 md:px-8 border overflow-hidden rounded-base ${
    isExpanded ? 'pb-2 pt-4 md:pt-8 lg:pt-6' : 'py-6 md:py-8'
  }`

  const buttonClass =
    'absolute inset-y-0 right-0 fill-neutral-900 text-base font-medium leading-tight m-0 my-4 bg-inherit mt-3 mt-1 text-lg border-0 text-neutral-black cursor-pointer block p-0 relative text-left w-full'

  const titleClass = 'mr-8 inline-block font-medium text-3xl'

  const chrevonClass = `absolute right-0 h-8 w-8 top-0 bottom-0 m-auto text-neutral-black transition ease-in-out duration-1000 ${
    isExpanded ? 'rotate-180' : 'top-0'
  }`

  const contentDividerClass = `h-0 mb-0 ${isExpanded && 'h-4 -mb-1'}`

  const contentClass = `pt-0 pb-4 pl-4 md:pl-8 overflow-hidden text-lg font-medium lg:pr-24 md:pr-16 pr-6 ${
    !isExpanded ? 'hidden h-0' : 'h-auto'
  }`

  return (
    <div className={'m-0'} data-component={dataComponent}>
      <h4 className={headerClass}>
        <button className={buttonClass}>
          <span className={titleClass}>{title}</span>
          <ChevronDown className={chrevonClass} />
        </button>
      </h4>
      <div
        aria-hidden={true}
        className={contentDividerClass}
        style={{
          transition:
            'height 500ms ease-in-out, margin-bottom 500ms ease-in-out'
        }}
      ></div>
      <div
        className={contentClass}
        style={{ transition: 'transition height 0.5s ease-out' }}
      >
        {children}
      </div>
    </div>
  )
}
