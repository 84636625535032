import { type Address } from '@commercetools/platform-sdk'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/ReadonlyArray'
import { pipe } from 'fp-ts/lib/function'

import { getCommerceDataFromLineItems } from '../getCommerceDataFromLineItems'
import {
  AddOnSkuToVariantId,
  type BrazeChildComponents,
  type BrazeLineItem,
  type FlattenedComponents,
  OntechPackage,
  OntechPackageAddOns,
  type VariantIds,
  isValidKey,
  isVariantId,
  systemPieceSkus
} from './types'

export const getPurchasedComponents = (cart: ImmutableCart, locale: string) => {
  if (!cart) {
    return []
  } else {
    const items = getCommerceDataFromLineItems('checkout')(cart?.lineItems)

    // remove products without variants or brands
    const commerceProducts = items.checkout?.products?.filter(
      product =>
        Array.isArray(product.variant) || typeof product.brand === 'undefined'
    )

    // creates an array of objects with name, quantity, and package components if available
    // @ts-expect-error
    const brazeCartProducts: readonly BrazeLineItem[] = commerceProducts?.map(
      product => {
        if (!Array.isArray(product.variant)) {
          return {
            name: product.name,
            quantity: product.quantity,
            sku: product.id
          }
        } else {
          // If there are variants, reduce the variants to an object of package components
          const packageComponents: BrazeChildComponents = product.variant.map(
            childProduct => ({
              name: childProduct?.name[locale],
              quantity: childProduct?.quantity?.toString(),
              sku: childProduct?.sku
            })
          )

          return {
            name: product?.name,
            quantity: product?.quantity,
            sku: product.id,
            packageComponents
          }
        }
      }
    )

    return brazeCartProducts
  }
}

/**
 * Quantity of package product pieces that can be pro installed
 */
export const getPiecesQuantity = (
  comparisonArray: readonly string[],
  inputElement: readonly BrazeLineItem[]
) => {
  return inputElement.reduce((quantity, component) => {
    if (comparisonArray.includes(component.sku)) {
      return quantity + Number(component.quantity)
    } else {
      return quantity
    }
  }, 0)
}

/**
 * On Tech variant ID for pro install url
 */
export const installationService = (skuCountForOnTech: number) => {
  if (skuCountForOnTech > 0 && skuCountForOnTech <= 10) {
    return '39301229838400'
  } else if (skuCountForOnTech >= 11 && skuCountForOnTech <= 15) {
    return '39301229871168'
  } else if (skuCountForOnTech >= 16 && skuCountForOnTech <= 20) {
    return '39301229903936'
  } else if (skuCountForOnTech >= 21 && skuCountForOnTech <= 25) {
    return '39328060047424'
  } else if (skuCountForOnTech >= 26 && skuCountForOnTech <= 30) {
    return '39301229805632'
  } else if (skuCountForOnTech >= 31) {
    return ''
  } else {
    return ''
  }
}

/**
 * Returns which pieces package
 */
export const getPackage = (productArray: readonly FlattenedComponents[]) => {
  const piecesVariantIds = {
    [OntechPackage.piece_10]: 0, //SimpliSafe Up to 10 Pieces Installation
    [OntechPackage.piece_15]: 0, //SimpliSafe Up to 15 Pieces Installation
    [OntechPackage.piece_20]: 0, //SimpliSafe Up to 20 Pieces Installation
    [OntechPackage.piece_25]: 0, //SimpliSafe Up to 25 Pieces Installation
    [OntechPackage.piece_30]: 0 //SimpliSafe Up to 30 Pieces Installation
  }
  const quantity = getPiecesQuantity(systemPieceSkus, productArray)
  const gotInstallationService = installationService(Number(quantity))
  return {
    ...piecesVariantIds,
    [gotInstallationService]:
      ((gotInstallationService && piecesVariantIds[gotInstallationService]) ||
        0) + 1
  }
}

/**
 * Quantity of product add on pieces that can be pro installed
 */
export const getAddOns = (productArray: readonly FlattenedComponents[]) => {
  const emptyObject = {
    [OntechPackageAddOns.smartLock]: 0,
    [OntechPackageAddOns.videoDoorbell]: 0,
    [OntechPackageAddOns.outdoorCamera]: 0
  }
  return productArray.reduce((accumulator, product) => {
    if (isValidKey(product.sku)) {
      const variantId = AddOnSkuToVariantId[product.sku]
      return variantId
        ? {
            ...accumulator,
            [variantId]: (accumulator[variantId] || 0) + product.quantity
          }
        : accumulator
    } else {
      return accumulator
    }
  }, emptyObject)
}

export const getVariantIds = (productArray: readonly FlattenedComponents[]) => {
  const combinedVariantIds: VariantIds = {
    ...getPackage(productArray),
    ...getAddOns(productArray)
  }

  return Object.keys(combinedVariantIds)
    .filter(isVariantId)
    .filter(variantId => combinedVariantIds[variantId] > 0)
    .map(variantId => `${variantId}_${combinedVariantIds[variantId]}`)
}

export const isProInstallServicePurchased = (cart: ImmutableCart): boolean => {
  const items = getCommerceDataFromLineItems('checkout')(cart?.lineItems)
  return pipe(
    items?.checkout?.products || [],
    A.some(item => item.id === 'SSPSH-ON')
  )
}

/**
 * creates a custom onTech URL if user purchases pro install service
 */
export const getOnTechUrl = (
  locale: string,
  orderId: string,
  shippingAddress: O.Option<Address>,
  cart: ImmutableCart
) => {
  const emptyArray: readonly FlattenedComponents[] = []
  const brazeCartProducts = getPurchasedComponents(cart, locale)
  const onTechUrlPath =
    'https://www.ontechsmartservices.com/pages/referral?clientName=simplisafe&variants='

  const onTechBrazeCartProducts = brazeCartProducts.reduce((acc, item) => {
    const newItem = {
      name: item.name,
      quantity: item.quantity,
      sku: item.sku
    }

    const hasProInstall =
      item.packageComponents &&
      item.packageComponents.some(component => component.sku === 'SSPSH-ON')

    if (item.packageComponents && hasProInstall) {
      const flattenedComponents = item.packageComponents.map(comp => ({
        name: comp.name,
        quantity: Number(comp.quantity),
        sku: comp.sku
      }))
      return acc.concat(flattenedComponents)
    } else {
      return acc.concat([newItem])
    }
  }, emptyArray)

  const isNotOver30Pieces =
    getPiecesQuantity(systemPieceSkus, onTechBrazeCartProducts) < 31

  const getZipCodeFromShippingAddress = (): string => {
    return pipe(
      shippingAddress,
      O.match(
        () => '',
        (data: Address) =>
          data.postalCode ? data.postalCode.substring(0, 5) : '' //zipcode can autocorrect to xxxxx-xxxx format and onTech will only accept first 5 digits
      )
    )
  }

  return isProInstallServicePurchased(cart) && isNotOver30Pieces
    ? `${onTechUrlPath}${getVariantIds(
        onTechBrazeCartProducts
      )}&zipcode=${getZipCodeFromShippingAddress()}&partnerRefId=${orderId}`
    : ''
}
