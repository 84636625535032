import { None } from '@simplisafe/ewok'
import { type LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

export const mockLineItemAccessory: LineItem = {
  discountedPrice: None(),
  discountedPricePerQuantity: None(),
  isGift: false,
  lineItemId: 'abc-123',
  name: {
    'en-GB': 'Indoor Camera',
    'en-US': 'Indoor Camera'
  },
  price: 69.99,
  productType: 'accessory',
  quantity: 1,
  sku: 'SSCM2',
  taxedPrice: None(),
  totalPrice: 69.99
}

export const mockLineItemPackage: LineItem = {
  child: [
    {
      name: {
        'en-GB': 'Base Station',
        'en-US': 'Base Station'
      },
      quantity: 1,
      sku: 'SSBS3W'
    }
  ],
  custom: {
    fields: {
      package_parent_id: 'bcd-234',
      product_is_bms: false
    },
    type: {
      id: 'abc-123',
      typeId: 'type'
    }
  },
  discountedPrice: None(),
  discountedPricePerQuantity: None(),
  isGift: false,
  lineItemId: 'bcd-234',
  name: {
    'en-GB': 'Haven',
    'en-US': 'Haven'
  },
  price: 549.29,
  productType: 'package_parent',
  quantity: 1,
  sku: 'haven-en-GB',
  taxedPrice: None(),
  totalPrice: 549.29
}

export const mockLineItemChild: LineItem = {
  discountedPrice: None(),
  discountedPricePerQuantity: None(),
  isGift: false,
  lineItemId: 'foo-123',
  custom: {
    fields: {
      package_parent_id: 'bcd-234',
      product_is_bms: false
    },
    type: {
      id: 'abc-123',
      typeId: 'type'
    }
  },
  name: {
    'en-GB': 'Base Station',
    'en-US': 'Base Station'
  },
  price: 69.99,
  productType: 'sensor',
  quantity: 1,
  sku: 'SSBS3W',
  taxedPrice: None(),
  totalPrice: 69.99
}
