import classNames from 'classnames'
import { useField } from 'formik'
import React, { forwardRef, type HTMLAttributes } from 'react'

export type Props = HTMLAttributes<HTMLInputElement> & {
  readonly fullWidth?: boolean
  readonly hasError?: boolean
  readonly id?: string
  readonly name: string
  readonly onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  readonly placeholder?: string
  readonly type?: 'email' | 'text'
}

export const FormTextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      fullWidth,
      hasError,
      id,
      name,
      onKeyDown,
      placeholder,
      type = 'text',
      ...rest
    }: Props,
    ref
  ) => {
    const [field, meta] = useField(name)

    return (
      <input
        id={id}
        type={type}
        {...field}
        name={name}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
        {...rest}
        className={classNames(
          'placeholder:text-neutral-dark-100 rounded-base border-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size h-11 border border-solid p-3',
          {
            'border-sale text-sale': (meta.touched && meta.error) || hasError,
            'w-full': fullWidth
          },
          rest.className
        )}
        data-testid={`input-${name}`}
      />
    )
  }
)
