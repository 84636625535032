import React, { type ReactNode } from 'react'

type Props = {
  readonly children: ReactNode
}

export function ImageColumn({ children }: Props) {
  return (
    <div
      className="rounded-base mb-5 aspect-[622/377] w-full overflow-hidden md:mb-10"
      data-component="TwoColumnImageBlurbImage"
    >
      {children}
    </div>
  )
}
