import { type LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import { type TrackEvent } from '../analytics'
import { brazeTrackCartEvent } from '../braze'
import { getCommerceDataFromLineItem } from '../getCommerceDataFromLineItems'

const GTM_ADD_CART_EVENT = 'eec.add'
const GTM_ADD_CART_CATEGORY = 'eec'
const GTM_ADD_CART_ACTION = 'addtocart'

export const trackAddLineItemToCartEvent = (
  lineItem: LineItem,
  trackEvent: TrackEvent,
  lineItems: readonly LineItem[],
  quantity?: number
) => {
  const quantityAdjustedLineItem = {
    ...lineItem,
    quantity: quantity || lineItem.quantity
  }

  const ecomm = getCommerceDataFromLineItem('add')(lineItems)(
    quantityAdjustedLineItem
  )

  trackEvent({
    ecommerce: ecomm,
    event: 'addToCart'
  })

  trackEvent({
    ecommerce: ecomm,
    event: GTM_ADD_CART_EVENT,
    eventAction: GTM_ADD_CART_ACTION,
    eventCategory: GTM_ADD_CART_CATEGORY
  })

  brazeTrackCartEvent({
    eventType: 'add_to_cart',
    productTrackingData: ecomm
  })
}
