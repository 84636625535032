import { ninetailedExperimentsDataKey } from '@ecomm/data-constants'
import {
  COOKIE_BRAZE_PROMO_VIEWED,
  COOKIE_BRAZE_SITE_VISITED,
  COOKIE_LEAD_DATA,
  getCookie,
  getUserId,
  getValueFromLeadData,
  getVisitorId,
  setCookie
} from '@ecomm/shared-cookies'
import { getBraze } from '@ecomm/shared-window'
import {
  get as sessionStorageGet,
  remove as sessionStorageRemove
} from '@ecomm/utils'
import {
  isNilOrEmpty,
  isNotEmpty,
  isNotNil,
  localStorage
} from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import type { LeadGenCaptureResponse } from '@simplisafe/ss-ecomm-data/leads/capture'
import {
  apiKey,
  brazeUKSubscriptionGroup,
  brazeUSSubscriptionGroup,
  proxyApiUrl
} from '@simplisafe/ss-ecomm-data/secrets/braze'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'

import { SESSION_STORAGE_FIRST_VISITED_URL } from '../TrackingProvider/index'
import type { BrazeProps } from '../types/braze'
import type {
  BrazeQuoteWizardResponse,
  Ecommerce,
  Product
} from '../types/tracking'
import { brazeLogCustomEvent } from './lib/brazeLogCustomEvent'
import { brazeSetCustomUserAttributes } from './lib/brazeSetCustomUserAttributes'
import { EVENTS_LOGGING_DELAY } from './lib/constants'
import { handleBrazeImmediateFlush } from './lib/handleBrazeImmediateFlush'
import { shouldTrackUserEvent } from './lib/shouldTrackUserEvent'
import { updateLeadsData } from './lib/updateLeadsData'
export { brazeLogCustomEvent } from './lib/brazeLogCustomEvent'
export { brazeSetCustomUserAttributes } from './lib/brazeSetCustomUserAttributes'

import { pipe } from 'fp-ts/lib/function'
import { formatPhoneNumber } from './lib/formatPhoneNumber'
import { devThrowError } from '@ecomm/error-handling'
const { get, set } = localStorage

export const generateDynamicPackagePageURL = (attributeHash: string): string =>
  `/product/system/${attributeHash}`

export const convertQueryParamsToObject = (
  queryParams: string
): Record<string, string> => {
  return Object.fromEntries(new URLSearchParams(queryParams))
}

export type BrazeChildComponent = {
  readonly name: string
  readonly quantity: string
}

export type BrazeChildComponents = readonly BrazeChildComponent[]

export type BrazeLineItem = {
  readonly name: string
  readonly sku: string
  readonly quantity: number
  readonly packageComponents?: BrazeChildComponents
}

export type VariationEnteredEvent = {
  readonly Test_name: string
  readonly Variation_name: string
  readonly Test_start_date?: string
  readonly Test_end_date?: string
}

const LOCAL_STORAGE_EMAIL_PROMO = 'email_promo'
export let brazeInitialized = false

// initialize Braze Web SDK
export const initBraze = () => {
  const loggingEnabled =
    process.env['GATSBY_ENABLE_BRAZE_SDK_LOGGING'] === 'true'
  getBraze(braze => {
    const location = window.location
    loggingEnabled &&
      braze.setLogger((message: string) => {
        console.info(
          message.replace(apiKey, 'REDACTED').replace(proxyApiUrl, 'REDACTED')
        )
      })
    brazeInitialized = braze.initialize(apiKey, {
      baseUrl: proxyApiUrl,
      enableLogging: loggingEnabled
    })
    braze.automaticallyShowInAppMessages()
    // optionally set the current user's External ID
    location.hash.includes('braze_eid') ? setUserIdOnInit(braze) : null
  })
}

// Set User ID from URL hash if available
const setUserIdOnInit = (braze: BrazeProps) => {
  const windowUrl: string | undefined = window.location.href

  const match: readonly string[] = windowUrl?.match(
    /#braze_eid=[A-Za-z0-9]+/
  ) || ['']
  const brazeEid: string = match.toString().split('=')[1] || ''
  braze.openSession()
  braze.changeUser(brazeEid)
  set('braze_eid', brazeEid)
  console.info(
    `Braze: In function setUserIdOnInit found braze_eid query param, called changeUser with braze_eid : ${brazeEid}`
  )
  sendStoredNinetailedExperimentVariationsData()
}

// Fires up when a leads form is submitted
export const handleBrazeTrackingEvent = async (
  data?: LeadGenCaptureResponse,
  channel?: string,
  firstSubmission = false
) => {
  !getCookie(COOKIE_LEAD_DATA) &&
    setCookie(COOKIE_LEAD_DATA, data, cookiesOption)
  await updateLeadsData(data, channel, firstSubmission)
  console.info(
    `Braze: In function handleBrazeTrackingEvent, called updateLeadsData with lead data: ${JSON.stringify(
      { leadId: data?.leadId, externalId: data?.externalId }
    )}`
  )
  sendStoredNinetailedExperimentVariationsData()
}

// Tracks page visit
export const brazeTrackPageVisit = () =>
  shouldTrackUserEvent() && pageVisitTrackingEvent()

const pageVisitTrackingEvent = () => {
  getBraze(braze => {
    const utmParams = getUTMParams()
    setEmailSessionStorageValues(utmParams)
    !getCookie(COOKIE_BRAZE_SITE_VISITED) &&
      braze.logCustomEvent('site_visited', utmParams)
    setCookie(COOKIE_BRAZE_SITE_VISITED, true)
    setEmailAddressFromLeadDataCookie()
    logPageVisited()
  })
}

const setEmailSessionStorageValues = (utmParams: Record<string, string>) => {
  const emailPromoLocalStorage = get(LOCAL_STORAGE_EMAIL_PROMO) || null
  const setEmailPromo: boolean =
    isNotNil(utmParams['utm_code']) && isNilOrEmpty(emailPromoLocalStorage)
  const utmSource: string = utmParams['utm_source'] || ''
  const utmMedium: string = utmParams['utm_medium'] || ''
  const isEmailUrl: boolean =
    utmSource === 'lead_email' && utmMedium === 'email'
  const captureEmailVisit: boolean =
    isEmailUrl && isNilOrEmpty(emailPromoLocalStorage)
  const captureEmailUser: boolean =
    isEmailUrl && isNotNil(emailPromoLocalStorage)

  setEmailPromo && set(LOCAL_STORAGE_EMAIL_PROMO, utmParams['utm_code'])
  captureEmailVisit && set('email_visit', 'true')
  captureEmailUser && set('email_user', 'true')
}

// returns an object containing each UTM parameter in page URL
const getUTMParams = () => {
  const locationSearch: string = window.location.search
  return locationSearch
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj: Record<string, string>, pair) => {
      const [key, value] = pair.map(decodeURIComponent)
      return key && value ? { ...obj, [key]: value } : { ...obj }
    }, {})
}

// Capture pages visited events
const logPageVisited = () => {
  getBraze(braze => {
    const pageUrl: string = window.location.href
    const urlHash: string = window.location.hash

    const cartPage: boolean =
      pageUrl.includes('cart') && !pageUrl.includes('checkout')
    const checkoutShippingPage: boolean =
      pageUrl.includes('cart/checkout') && isNilOrEmpty(urlHash)

    const urlEventIdMapping: { [key: string]: string } = {
      'build-my-system': 'visit_bms',
      'choose-monitoring': 'visit_choose_monitoring',
      'contact-us': 'visit_contact_us',
      feature: 'visit_features',
      'home-security-shop': 'visit_shop',
      'home-security-system-': 'visit_package',
      reviews: 'visit_reviews'
    }

    Object.keys(urlEventIdMapping).forEach(
      key =>
        pageUrl.includes(key) &&
        braze.logCustomEvent(urlEventIdMapping[key] || '')
    )
    cartPage && braze.logCustomEvent('visit_cart')
    checkoutShippingPage && braze.logCustomEvent('visit_checkout_shipping')
  })
}

// Capture answers submitted in Quote Wizard
export const brazeTrackQuoteWizardSubmission = (
  responses: readonly BrazeQuoteWizardResponse[],
  qwWithRecs = false
) => {
  getBraze(braze => {
    setTimeout(() => {
      updateBrazeLeadCustomData()
      const eventId = `qw_${qwWithRecs ? 'recs_' : ''}complete`
      brazeLogCustomEvent(eventId)
      braze &&
        responses
          .filter(
            response => isNotNil(response.question) && isNotNil(response.answer)
          )
          .forEach(response =>
            braze
              .getUser()
              .setCustomUserAttribute(
                safeProp('question', response).getOrElse(''),
                safeProp('answer', response).getOrElse('')
              )
          )
      braze.requestImmediateDataFlush(
        handleBrazeImmediateFlush({ caller: 'brazeTrackQuoteWizardSubmission' })
      )
    }, EVENTS_LOGGING_DELAY)
  })
}

export const brazeTrackGuidedSystemBuilderRecommendation = (
  attributeHash: string,
  guidedSystemBuilderQueryParams: string,
  responses: readonly BrazeQuoteWizardResponse[]
) => {
  getBraze(braze => {
    setTimeout(() => {
      const eventId = 'qw_recs_complete'
      const queryParams = convertQueryParamsToObject(
        guidedSystemBuilderQueryParams
      )
      updateBrazeLeadCustomData()
      brazeLogCustomEvent(eventId, queryParams)

      braze
        .getUser()
        .setCustomUserAttribute(
          'QW_RECS_URL',
          generateDynamicPackagePageURL(attributeHash)
        )

      responses
        .filter(
          response => isNotNil(response.question) && isNotNil(response.answer)
        )
        .forEach(response => {
          braze
            .getUser()
            .setCustomUserAttribute(
              safeProp('question', response).getOrElse(''),
              safeProp('answer', response).getOrElse('')
            )
        })

      queryParams['name'] && braze.getUser().setFirstName(queryParams['name'])

      braze.requestImmediateDataFlush(
        handleBrazeImmediateFlush({
          caller: 'brazeTrackGuidedSystemBuilderRecommendation',
          eventId,
          ...queryParams
        })
      )
    }, EVENTS_LOGGING_DELAY)
  })
}

// Capture whether a user saw the promo
export const brazeTrackPromoView = () => {
  const logPromoViewedEvent: boolean =
    shouldTrackUserEvent() && !getCookie(COOKIE_BRAZE_PROMO_VIEWED)
  logPromoViewedEvent &&
    brazeLogCustomEvent('promo_viewed', {
      channel: attributeChannel(),
      promo_offer_seen: true
    })
  shouldTrackUserEvent() && setCookie(COOKIE_BRAZE_PROMO_VIEWED, true)
  updateBrazeLeadCustomData()
}

export const updateBrazeLeadCustomData = () => {
  getBraze(() => {
    const leadId = getValueFromLeadData('leadId')
    leadId ? setEventUserAttributes() : null
  })
}

export const setCustomBrazeCheckoutError = () => {
  getBraze(braze => {
    setEmailAddressFromLeadDataCookie()
    brazeLogCustomEvent('checkout_error')
    braze.requestImmediateDataFlush(
      handleBrazeImmediateFlush({ caller: 'setCustomBrazeCheckoutError' })
    )
  })
}

/**
 * Typescript is really bad at figuring out that something is a key of an object,
 * so we have to help it along with a type guard.
 */
const isEcommerceKey = (key: string): key is keyof Ecommerce => {
  const validKeys = [
    'add',
    'click',
    'remove',
    'detail',
    'checkout',
    'purchase',
    'currencyCode'
  ]
  if (validKeys.includes(key)) {
    return true
  } else {
    devThrowError(Error(`Invalid key passed to braze eventType: ${key}`))
    return false
  }
}

// Track which products are added, removed, or clicked from cart
export const brazeTrackCartEvent = (eventData: {
  readonly eventType: 'add_to_cart' | 'remove_from_cart' | 'system_click'
  readonly productTrackingData: Ecommerce
}) => {
  getBraze(braze => {
    const eventType: string = eventData.eventType
    const actionTypeKey: string = eventType.substring(0, eventType.indexOf('_'))

    const productData: Product = pipe(
      O.Do,
      // get the productTrackingData from the event data and assign to to the key of 'ecommerce'
      O.bind('ecommerce', () => O.fromNullable(eventData.productTrackingData)),
      // get the key from the event data and check if it is a valid ecommerce key so we can use it to access the ecommerce object and assign the key to 'key'
      O.bind('key', () => O.fromPredicate(isEcommerceKey)(actionTypeKey)),
      // the two values here are created from the bind functions above, so if both are true we can get a value from the ecommerce object using the key
      O.map(({ ecommerce, key }) => ecommerce[key]),
      // this can be either a string or Products, which is weird, but we want to remove values that are just strings
      O.chain(O.fromPredicate(x => typeof x !== 'string')),
      // make sure it's not undefined
      O.chain(O.fromNullable),
      // get the first product
      O.chain(x => O.fromNullable(x.products?.[0])),
      // if we don't have anything, we just fallback to an empty object
      O.getOrElseW(() => ({}))
    )

    const properties = {
      channel: attributeChannel(),
      env: window.location.hostname,
      id: productData.id || '',
      name: productData.name || '',
      price: productData.price || 0,
      quantity: productData.quantity || 1
    }
    braze.openSession()
    brazeLogCustomEvent(eventType, properties)
    braze.requestImmediateDataFlush()
  })
}

// Purchase data capturing
export const brazeTrackPurchaseComplete = (eventData: {
  readonly orderId: string
  readonly systemInOrder?: boolean
  readonly phoneNumber: string
  readonly products: readonly Product[]
  readonly currencyCode: string // it is passed in as string type from where it's coming from in ss-ecomm-frontend
  readonly onTechUrl?: string
}) => {
  const userId: string = getUserId() || ''
  const orderId: string = eventData.orderId
  const currencyCode: string = eventData.currencyCode
  const products = eventData.products
  const phoneNumber = eventData.phoneNumber
  const systemInOrder: boolean = safeProp('systemInOrder', eventData).getOrElse(
    false
  )
  const onTechUrl: string = safeProp('onTechUrl', eventData).getOrElse('')

  brazeSetCustomUserAttributes({
    'Last Lead Source': 'purchase',
    'Last Order ID': orderId,
    'Last User ID': userId,
    phone_number: phoneNumber,
    onTechUrl: onTechUrl
  })

  getBraze(braze => {
    braze &&
      (setEventUserAttributes(),
      isNotEmpty(userId) &&
        braze.getUser().addAlias(userId, 'simplisafe_user_id'),
      setEmailAddressFromLeadDataCookie(),
      setPhoneNumber(phoneNumber),
      addUserToSubscriptionGroup())

    const logPurchase = (sku: string, price: number, quantity = 1) =>
      braze.logPurchase(sku, price, currencyCode, quantity, {
        order_id: orderId
      })

    products.forEach(product => {
      const sku: string = product.id || ''
      const price: number = product.price || 0
      const quantity: number = product.quantity || 1
      logPurchase(sku, price, quantity)
      // if it has an underscore, strip and log that too; see ECP-1838
      sku.includes('_') &&
        logPurchase(sku.substr(0, sku.indexOf('_')), price, quantity)
    })

    systemInOrder && logPurchase('SSCS3', 0)

    const properties = {
      order_id: orderId,
      user_id: userId
    }

    brazeLogCustomEvent('purchase_complete', {
      ...properties,
      order_source: 'website'
    })
    brazeLogCustomEvent('checkout', properties)
    braze.requestImmediateDataFlush()
  })
}

export const brazeTrackCartDetails = (eventData: {
  readonly cartId: string
  readonly cartTotal: string
  readonly coupon: string
  readonly discountedCartTotal: string
  readonly products: readonly BrazeLineItem[]
}) => {
  const properties = {
    last_cart_components: eventData.products,
    last_cart_coupon: eventData.coupon,
    last_cart_id: eventData.cartId,
    last_cart_total: eventData.cartTotal,
    last_discounted_cart_value: eventData.discountedCartTotal
  }
  brazeLogCustomEvent('cart_details', properties)
}

const setEventUserAttributes = () =>
  brazeSetCustomUserAttributes({
    Channel: attributeChannel(),
    Environment: window.location.hostname,
    page_path: window.location.pathname,
    vid: getVisitorId() || '',
    'First Visited Url': sessionStorageGet(SESSION_STORAGE_FIRST_VISITED_URL)
  })

const setEmailAddressFromLeadDataCookie = () => {
  getBraze(braze => {
    const emailAddress = getValueFromLeadData('email')
    emailAddress && braze.getUser().setEmail(emailAddress)
  })
}

export const setEmailAddress = (email: string) =>
  getBraze(braze => {
    braze.getUser().setEmail(email?.trim())
    braze.requestImmediateDataFlush()
  })

export const setPhoneNumber = (phoneNumber: string) =>
  getBraze(braze =>
    braze.getUser().setPhoneNumber(formatPhoneNumber(phoneNumber))
  )

export const setFirstName = (firstName: string) =>
  getBraze(braze => braze.getUser().setFirstName(firstName?.trim()))

export const setLastName = (lastName: string) =>
  getBraze(braze => braze.getUser().setLastName(lastName?.trim()))

export const addUserToSubscriptionGroup = () =>
  getBraze(braze =>
    braze
      .getUser()
      .addToSubscriptionGroup(
        process.env['LOCALE'] === 'en-GB'
          ? brazeUKSubscriptionGroup
          : brazeUSSubscriptionGroup
      )
  )

export const addUserToSMSSubscriptionGroup = () =>
  process.env['GATSBY_BRAZE_SMS_SUBSCRIPTION_ID'] &&
  getBraze(braze =>
    braze
      .getUser()
      .addToSubscriptionGroup(process.env['GATSBY_BRAZE_SMS_SUBSCRIPTION_ID'])
  ) //Marketing sms subscription group id

export const setPhoneNumberAndSubscribeToMarketingSMS = (
  phoneNumber: string
) => {
  setPhoneNumber(phoneNumber)
  addUserToSMSSubscriptionGroup()
  getBraze(braze => braze.requestImmediateDataFlush())
}

export const setPhoneNumberAndSubscribeToLifecycleSMS = (
  phoneNumber: string
) => {
  setPhoneNumber(phoneNumber)
  addUserToSubscriptionGroup()
  getBraze(braze => braze.requestImmediateDataFlush())
}

const attributeChannel = () =>
  `FCP ${process.env['LOCALE'] === 'en-GB' ? 'UK' : 'US'} Site`

export const sendNinetailedExperimentVariationsData = (
  experimentsData: readonly VariationEnteredEvent[]
) =>
  !!getBraze(braze => {
    experimentsData.forEach(e => {
      brazeLogCustomEvent('Variation_entered', e)
    })
    braze.requestImmediateDataFlush()
    return true
  })

const sendStoredNinetailedExperimentVariationsData = () => {
  // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
  const events: O.Option<readonly VariationEnteredEvent[]> = O.tryCatch(() =>
    JSON.parse(sessionStorageGet(ninetailedExperimentsDataKey) || '[]')
  )

  O.isSome(events) && sendNinetailedExperimentVariationsData(events.value)
  sessionStorageRemove(ninetailedExperimentsDataKey)
}

export const setBrazeAttributeGoogleClientId = (clientId: string) => {
  brazeSetCustomUserAttributes({ 'Google Client ID': clientId })
}
