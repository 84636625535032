import { pipe } from 'fp-ts/lib/function'

import {
  CHANGE_MONITORING_URL,
  CHANGE_MONITORING_URL_REPLACEMENT,
  CHOOSE_MONITORING_URL,
  CHOOSE_MONITORING_URL_REPLACEMENT,
  OUTDOOR_CAMERA_CHANGE_MONITORING_URL_REPLACEMENT
} from './constants'
import { ReactNode } from 'react'

export type CartLineItemPropsLegacy = {
  readonly isBms?: boolean
  readonly isIndeterminatePrice: boolean
  readonly itemName: string
  readonly sku?: string
  readonly lineItemDisplayName?: string
  readonly price?: string
  readonly isFreeItem?: boolean
  readonly isDiscount?: boolean
  readonly isFreeShippingItem?: boolean
  readonly itemDescriptionContent?: ReactNode
  readonly itemLink?: string
  readonly onClickRemove?: () => void
  readonly outOfStockMessage?: ReactNode
  readonly isHighlightedLineItem?: boolean
  readonly quantityText?: string
  readonly clickLink?: (url: string) => void
  readonly trackQuantityChange?: (
    newQuantity: number,
    oldQuantity: number
  ) => void
}

export const renameLineItemProps = (
  lineItemProps: CartLineItemPropsLegacy,
  items?: readonly CartLineItemPropsLegacy[]
) => {
  const containsOutdoorSecurityCameraBundle =
    items &&
    items.some(
      item => item.lineItemDisplayName === 'Outdoor Security Camera Bundle'
    )

  const hasOutdoorSecurityCameraBundleSku = containsOutdoorSecurityCameraBundle

  const itemDescriptionContentElement: React.ReactElement = <
    React.ReactElement
  >lineItemProps.itemDescriptionContent

  const descriptionRaw: string =
    itemDescriptionContentElement?.props.productInformation?.description?.raw ||
    ''
  const newDescriptionRaw = pipe(
    descriptionRaw,
    hasOutdoorSecurityCameraBundleSku
      ? outdoorCameraBundleReplaceMonitoringUrl
      : replaceMonitoringUrl
  )

  const newItemDescriptionContentElement: React.ReactElement = isChanged(
    descriptionRaw,
    newDescriptionRaw
  )
    ? {
        ...itemDescriptionContentElement,
        props: {
          ...itemDescriptionContentElement.props,
          productInformation: {
            ...itemDescriptionContentElement.props.productInformation,
            description: {
              raw: newDescriptionRaw
            }
          }
        }
      }
    : itemDescriptionContentElement

  const newProps = isChanged(descriptionRaw, newDescriptionRaw)
    ? {
        ...lineItemProps,
        itemDescriptionContent: newItemDescriptionContentElement
      }
    : lineItemProps

  return newProps
}

const replaceMonitoringUrl = (s: string) =>
  s
    .replaceAll(CHOOSE_MONITORING_URL, CHOOSE_MONITORING_URL_REPLACEMENT)
    .replaceAll(CHANGE_MONITORING_URL, CHANGE_MONITORING_URL_REPLACEMENT)

const outdoorCameraBundleReplaceMonitoringUrl = (s: string) =>
  s
    .replaceAll(CHOOSE_MONITORING_URL, CHOOSE_MONITORING_URL_REPLACEMENT)
    .replaceAll(
      CHANGE_MONITORING_URL,
      OUTDOOR_CAMERA_CHANGE_MONITORING_URL_REPLACEMENT
    )

const isChanged = (older: string, newer: string) =>
  older && newer && older !== newer
