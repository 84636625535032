import { useLocale } from '@ecomm/data-hooks'
import { path } from '@simplisafe/ewok'
import { type Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { getCommerceDataFromProduct } from '../analytics'
import { brazeTrackCartEvent } from '../braze'
import { fbTrackAddProductsToCart } from '../facebook'
import { type TrackingData } from '../types/tracking'

const GTM_ADD_CART_EVENT = 'eec.add'
const GTM_ADD_CART_CATEGORY = 'eec'
const GTM_ADD_CART_ACTION = 'addtocart'

const useTrackingProductAddToCart = (
  productDetail?: Product,
  label?: string
) => {
  const { trackEvent } = useTracking<TrackingData>()
  const locale = useLocale()

  return useCallback(
    (quantity: number) => {
      const productName = path(['name', locale], productDetail)

      const ecomm =
        productDetail &&
        getCommerceDataFromProduct(productDetail, 'add', quantity)

      trackEvent({
        ecommerce: ecomm,
        event: 'addToCart'
      })

      trackEvent({
        ecommerce: ecomm,
        event: GTM_ADD_CART_EVENT,
        eventAction: GTM_ADD_CART_ACTION,
        eventCategory: GTM_ADD_CART_CATEGORY,
        eventLabel: label ? label : productName
      })

      ecomm &&
        brazeTrackCartEvent({
          eventType: 'add_to_cart',
          productTrackingData: ecomm
        })
      ecomm?.add?.products && fbTrackAddProductsToCart(ecomm.add.products)
    },
    [trackEvent, locale]
  )
}

export default useTrackingProductAddToCart
